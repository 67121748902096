import React from "react";
import "./FloatingActionButton.scss";
import addIcon from "./../../../assets/add-01.svg";
import addContactIcon from "./../../../assets/contact2-01-01.svg";
import encryptFileIcon from "./../../../assets/encrypt-01.svg";
import decryptFileIcon from "./../../../assets/decrypt-01.svg";
import ActionButton from "./ActionButton";

export default class FloatingActionButton extends React.Component {
  render() {
    const { onAddContact, onDecryptFile, onEncryptFile } = this.props;
    const iconsMap = new Map()
      .set("add-contact", {
        image: addContactIcon,
        content: "Add contact",
        onClick: onAddContact,
      })
      .set("decrypt-file", {
        image: decryptFileIcon,
        content: "Decrypt file",
        onClick: onDecryptFile,
      })
      .set("encrypt-file", {
        image: encryptFileIcon,
        content: "Encrypt file for myself",
        onClick: onEncryptFile,
      });

    return (
      <div className="fab-container d-md-flex d-lg-flex d-none">
        <div className="fab shadow">
          <div className="fab-content">
            <span className="material-icons">
              <img src={addIcon} />
            </span>
          </div>
        </div>
        {Array.from(iconsMap.keys()).map((iconId) => (
          <ActionButton
            key={iconId}
            onClick={iconsMap.get(iconId).onClick}
            image={iconsMap.get(iconId).image}
            tooltipId={iconId}
            tooltipContent={iconsMap.get(iconId).content}
          />
        ))}
      </div>
    );
  }
}
