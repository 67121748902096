import moment from "moment";

export default class GlobalService {
    upperCase = (text) => {
        let words = text.trim().split(' ');
        words = words.map((word) => {
            const wordChars = word.split('');
            wordChars[0] = wordChars[0].toUpperCase();
            return wordChars.join('');
        });

        return words.join(' ');
    }

    extractFirstLetter(text) {
        let characters = text.trim().split('');
        return characters[0];
    }

    generateFileDateFromTimeStamp = (timestamp) => {
        const timestampInSeconds = timestamp * 1000;
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        const month = months[new Date(timestampInSeconds).getMonth()];
        const day = new Date(timestampInSeconds).getDate();
        const year = new Date(timestampInSeconds).getFullYear();

        return `${day} ${month} ${year}`;
    };

    listenForOutsideClicks = (listening, setListening, menuRef, setIsOpen) => {
        return () => {
            if (listening) return;
            if (!menuRef.current) return;
            setListening(true);
            [`click`, `touchstart`].forEach((type) => {
                document.addEventListener(`click`, (evt) => {
                    if (menuRef && menuRef.current) {
                        if (menuRef.current.contains(evt.target)) return;
                        setIsOpen(false);
                    }
                });
            });
        }
    };

    transformDateSettingsModal = (stringDate) => {
        const options = { day: 'numeric', month: 'short', year: 'numeric'}
        const date = new Date(stringDate);

        // Format will be 01-Oct-2000
        return date.toLocaleDateString(undefined, options);
    };
}