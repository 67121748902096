import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Button, Label } from "reactstrap";
import "./SettingsModal.scss";
import userLogo from "../../../assets/user_icon.png";
import { store } from "../../../Store/store";
import GlobalService from "../../../Services/Global/GlobalService";
import GoogleDriveService from "../../../Services/GoogleDrive/GoogleDriveService";
import { ContactsSubscribe } from "../../Global/ContactsSubscribe/ContactsSubscribe";
import { SubscribedContacts } from "../../Global/SubscriptionContacts/SubscribedContacts";
import ContactsSubscriptionService from "../../../Services/Subscription/ContactsSubscriptionService";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../../../Constants/GlobalConstants";
import SubscriptionInformation from "./SubscriptionInformation";

export const service = new ContactsSubscriptionService();

const subscriptionManagementTypes = ["business", "enterprise"];

export const SettingsModal = ({ isOpen, toggle }) => {
  const [subscriptionInformation, setSubscriptionInformation] = useState(null);

  const globalService = new GlobalService();

  const firstName = globalService.upperCase(
    store.getState().user.info.firstName
  );
  const lastName = globalService.upperCase(store.getState().user.info.lastName);

  useEffect(() => {
    if (isOpen) {
      try {
        service
          .getSubscriptionInformation()
          .then((subscriptionInfo) =>
            setSubscriptionInformation(subscriptionInfo)
          );
      } catch (error) {
        console.error(error);
        toast.error(ERROR_MESSAGES.ERROR_SUBSCRIPTION_INFO);
      }
    }

    return () => setSubscriptionInformation(null);
  }, [isOpen]);

  return (
    <Modal size="lg" isOpen={isOpen} toggle={() => toggle(false)} centered>
      <ModalBody>
        <div className="navbar-settings-container">
          <div className="d-flex flex-column navbar-settings-user-info">
            <div>
              <h5 className="section-title">Account</h5>
            </div>
            <div className="d-flex flex-row align-items-center">
              <img
                className="img-circle me-3"
                src={userLogo}
                width="35"
                height="35"
                alt=""
              />
              <span className="text-decoration-none color-black me-3">{`${firstName} ${lastName}`}</span>
            </div>
          </div>
          <div className="navbar-settings-section">
            <div className="mb-4">
              <h5 className="section-title pt-2">Subscription</h5>
              {subscriptionInformation && subscriptionInformation.length ? (
                <SubscriptionInformation
                  subscriptionInformation={subscriptionInformation}
                />
              ) : (
                <SubscriptionRow />
              )}
            </div>
          </div>
          {subscriptionInformation &&
          subscriptionInformation.length &&
          subscriptionManagementTypes.includes(
            subscriptionInformation[0].type
          ) ? (
            <div className="navbar-settings-section">
              <SubscriptionManagementRow />
            </div>
          ) : null}
        </div>
        <div className="d-flex flex-row justify-content-end">
          <Button type="button" onClick={() => toggle(false)}>
            Close
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

const SubscriptionManagementRow = () => {
  const [subscribers, setSubscribers] = useState([]);

  const getSubscribers = () => {
    service.getSubscribedContacts().then((accounts) => {
      setSubscribers(accounts);
    });
  };

  useEffect(() => {
    getSubscribers();
  }, [subscribers.length]);

  return (
    <div className="mb-4">
      <h5 className="section-title">Subscription management</h5>
      <div className="mb-4">
        <ContactsSubscribe
          subscribers={subscribers}
          onSubsUpdate={() => {
            getSubscribers();
          }}
        />
      </div>
      <div >
        <Label className='mb-3'><h5>Subscriptions</h5></Label>
        <SubscribedContacts
          subscribers={subscribers}
          refreshSubscribers={() => {
            getSubscribers();
          }}
        />
      </div>
    </div>
  );
};

const SubscriptionRow = () => {
  return (
    <div className="d-flex flex-row">
      <div className="d-flex flex-row me-4">
        <div className="sub-label">Status:</div>
        <div className="sub-info inactive-sub">Inactive</div>
      </div>
      <div className="d-flex flex-row justify-content-center align-items-center">
        <div className="sub-label">Plan:</div>
        <div className="sub-info inactive-sub">Free</div>
      </div>
      <div style={{ marginLeft: "auto" }}>
        <a
          href="https://borisb.test.onfastspring.com/"
          target="_blank"
          className="primary-button-color p-1 rounded"
          style={{ textDecoration: "none" }}
        >
          Buy
        </a>
      </div>
    </div>
  );
};

const LinkAccountRow = () => {
  const googleDriveService = new GoogleDriveService();

  return (
    <div className="mb-4">
      <h5 className="section-title">Link account</h5>
      <small>
        <p>
          Hi there! We have an amazing feature which allows for the file upload
          to your personal Google drive account after the encryption has
          finished.
        </p>
        <p>To activate it, just sign in with your Google account.</p>
      </small>
      <div>
        <div
          id="my-signin2"
          ref={() => {
            try {
              googleDriveService.renderSignIn();
            } catch (e) {
              console.warn("e", e);
            }
          }}
        ></div>
      </div>
    </div>
  );
};
