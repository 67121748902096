import React from "react";
import GlobalService from "../../../Services/Global/GlobalService";
import "./SettingsModal.scss";

const SubscriptionInformation = ({subscriptionInformation}) => {
    const globalService = new GlobalService();
    return (
        <div className="d-flex flex-column">
            <div className="d-flex flex-row mb-3">
                <div className="d-flex flex-row align-items-center me-2  mb-2">
                    <div className="sub-label">Status:</div>
                    <div className="sub-info active-sub">
                        {globalService.upperCase(subscriptionInformation[0].state)}
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center me-2  mb-2">
                    <div className="sub-label">Plan:</div>
                    <div className="sub-info active-sub">
                        {globalService.upperCase(subscriptionInformation[0].type)}
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row mb-3">
                <div className="d-flex flex-row align-items-center me-2">
                    <div className="sub-label">Charged:</div>
                    <div className="sub-info active-sub">
                        {globalService.transformDateSettingsModal(
                            subscriptionInformation[0].charged_date
                        )}
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center me-2">
                    <div className="sub-label">Expires:</div>
                    <div className="sub-info-second-row">
                        {globalService.transformDateSettingsModal(
                            subscriptionInformation[0].expires
                        )}
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row mb-2">
                <div className="d-flex flex-row align-items-center me-2">
                    <div className="sub-label">Contacts limit per user:</div>
                    <div className="sub-info-second-row">
                        {subscriptionInformation[0].contacts_limit_per_user}
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center me-2">
                    <div className="sub-label">Subscription members limit:</div>
                    <div className="sub-info-second-row">
                        {subscriptionInformation[0].subscription_members_limit}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionInformation;
