import React, { useState } from "react";
import "./FloatingActionButton.scss";
import { Tooltip } from "reactstrap";

const ActionButton = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <div
        className="sub-button shadow"
        onClick={props.onClick}
        id={`Tooltip-${props.tooltipId}`}
      >
        <span className="material-icons">
          <img src={props.image} />
        </span>
      </div>
      <Tooltip
        placement={"left"}
        isOpen={tooltipOpen}
        target={"Tooltip-" + props.tooltipId}
        toggle={toggleTooltip}
      >
        {props.tooltipContent}
      </Tooltip>
    </>
  );
};

export default ActionButton;
