import axios from "axios";
import ResponseTransformer from "./ResponseTransformer";
import {FILE_TYPE_NONE} from "../../Constants/GlobalConstants";
import moment from 'moment';
import {getAuth, setRefreshToken, setToken} from "../../global";

export default class HttpRefreshToken {
    instance = null;

    constructor() {
        this.instance = this.createInstance({Authorization: `Bearer ${getAuth().token}`, 'content-type':'application/json'});
        this.instance = this.initInterceptors(this.instance);
    }

    updateInstance = (headers) => {
        this.instance = this.createInstance(headers);
    };

    createInstance = (headers) => {
        return axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            timeout: 30000,
            headers: headers ? {...headers, "content-type": "application/json"} : {"content-type": "application/json"}
        })
    };

    getContactList = () => {
        return this.instance.get('/contactlist/show');
    };

    declineRequestedContactRequest = (email) => {
        return this.instance.post('/contactlist/request/decline', {'email': email});
    };

    acceptContactRequest = (email) => {
        return this.instance.post('/contactlist/request/accept', {'email': email});
    };

    declineContactRequest = (email) => {
        return this.instance.post('/contactlist/request/cancel', {'email': email});
    };

    inviteContact = (email) => {
        return this.instance.post('/contactlist/request/send', {'email': email});
    };

    deleteContact = (email) => {
        return this.instance.post('/contactlist/user/remove', {'email': email});
    };

    getSubscriptionInfo = () => {
        return this.instance.get('/subscription');
    };

    getSubscribedContacts = () => {
        return this.instance.get('/subscription/members');
    };

    addContactsSubscription = (members) => {
        return this.instance.post('/subscription/members/add', members);
    };

    removeSubscriber = (email) => {
        return this.instance.post('/subscription/members/remove', email);
    };

    pushKeys = (keys) => {
        const body = {publickey: keys.pub, privatekey: keys.priv};
        return this.instance.post('/user/pushkeys', body);
    };

    getPersonalKeys = () => {
        const prKRequest = this.instance.get('/user/getprivatekey');
        const puKRequest = this.instance.get('/user/getpublickey');
        return Promise.all([prKRequest, puKRequest]).then((resp) => {
            return {prK: resp[0], puK: resp[1]}
        });
    };

    getContactPublicKey = (email) => {
        return this.instance.get(`/user/getpublickey`, {
            params: {
                useremail: email
            }
        }  ).then((resp) => resp.data.publickey);
    };

    pushToFileHistory = (email, fileName, action, size, deviceId) => {
        const timestamp = moment().unix();
        const filenameSplitByDot = fileName.split('.');
        const body = {elements: {}};
        body.elements[timestamp] = {
            note: '',
            email: email,
            name: fileName,
            timestamp: `${timestamp}`,
            filetype: filenameSplitByDot.length > 0 ? filenameSplitByDot[filenameSplitByDot.length - 1].toLowerCase() : FILE_TYPE_NONE,
            action: action,
            size: size,
            deviceid: deviceId
        }
        return this.instance.post('/user/filehistory', body);
    };

    getFileHistory = () => {
       return this.instance.get('/user/filehistory').then((resp) => {
           const transformer = new ResponseTransformer();
           if (resp.data.filehistory){
               return transformer.transformFileHistoryToArray(JSON.parse(resp.data.filehistory));
           }

           return [];
       });
    };

    //Refresh token
    getRefreshAccessToken = () => {
        return axios.create({baseURL: process.env.REACT_APP_API_BASE_URL, 
            headers: {'Authorization': `Bearer ${getAuth().refreshToken}`}})
            .post('/refresh');
    };

    initInterceptors = (axiosInstance) => {
        const _self = this;
        axiosInstance.interceptors.request.use(
            async config => {
                config.headers = {
                    'Authorization': `Bearer ${getAuth().token}`,
                    'Accept': 'application/json'
                }
                return config;
            },
            error => {Promise.reject(error)}
        );

        axiosInstance.interceptors.response.use((response) => {
            return response
        }, async function (error) {
            const originalRequest = error.config;
            if (error.response.status === 500 && !originalRequest._retry) {
                originalRequest._retry = true;
                const response = await _self.getRefreshAccessToken();
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
                setToken(response.data.token);
                setRefreshToken(response.data.refresh_token);
                _self.updateInstance({'Authorization': `Bearer ` + response.data.token})
                return axiosInstance(originalRequest);
            }
            return Promise.reject(error);
        });

        return axiosInstance;
    };

}
