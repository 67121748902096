const auth = { token: '', refreshToken: ''};

export const getAuth = () => {
    return auth;
};

export const setToken = (token) => {
    auth.token = token;
};

export const setRefreshToken = (refreshToken) => {
    auth.refreshToken = refreshToken;
};