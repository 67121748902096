import React from 'react';
import {Button, Col, Container, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import './LoginComponent.scss';
import NavigationTabLogin from "../Global/NavigationTabLogin/NavigationTabLogin";
import {withRouter} from "react-router";
import PasswordResetModal from "../Modals/PasswordResetModal/PasswordResetModal";
import Http from "../../Services/Http/Http";
import {toast} from "react-toastify";
import KripteraButton from '../../Partials/KripteraButton/KripteraButton';
import { isEmailValid } from "../../helpers/helpers";
import {getAuth} from "../../global";

class Login extends React.Component {
    http = new Http();

    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            username: null,
            password: null,
            displayRegModal: this.props.match.params.status !== undefined,
            displayPasswordResetModal: false,
            isLoading: false
        }
    }

    toggleRegModal = (toggle) => {
        this.setState({displayRegModal: toggle});
    };

    togglePasswordResetModal = (toggle) => {
        this.setState({displayPasswordResetModal: toggle});
    };

    updateUsername = (username) => {
        this.setState({username: username});
    };

    updatePassword = (password) => {
        this.setState({password: password});
    };

    toggleLoadingButton = (toggle) => {
        this.setState({ isLoading: toggle });
    };

    login = () => {
        this.toggleLoadingButton(true);
        const {username, password} = this.state;

        this.http.login(username, password).then((response) => {
            this.toggleLoadingButton(false);
            this.props.history.push('/contacts');
        }).catch((e) => {
            console.error(e);
            if (e.response && parseInt(e.response.status) === 401) {
                toast.error('Wrong username or password');
            }
            if (e.response && parseInt(e.response.status) === 403) {
                toast.error('Email is not verified. To login you need to verify your email.');
            }
            this.toggleLoadingButton(false);
        })
    };

    componentDidMount() {
        if (getAuth().token.length > 0) {
            this.props.history.push('/contacts')
        }
    }

    render() {
        const {displayRegModal, displayPasswordResetModal, isLoading} = this.state;
        const isLoginButtonDisabled = this.state.username && this.state.password && isEmailValid(this.state.username) ? false : true;

        return (
            <Container fluid className='fill main-container-login'>
                <Row className='fill'>
                    <Col lg={6} md={6} xs={0} sm={0} className={'d-none d-md-block d-lg-block login-wallpaper'}>
                    </Col>
                    <Col lg={6} md={6} xs={12} sm={12} className='main-container-login-col py-md-5 py-sm-3 px-md-5 py-sm-2 col-12 col-sm-12 col-md-6 col-lg-6'>
                        <Row>
                            <Col sm={12} xs={12} lg={12} className='d-flex justify-content-center align-items-center'>
                                <NavigationTabLogin activeTab={'login'}/>
                            </Col>
                        </Row>
                        <Row className={'py-5'}>
                            <Col sm={12} xs={12} lg={12}>
                                <Row className={'mb-1 text-md-start text-sm-center'}>
                                    <Col className='text-60'>
                                        Kriptera
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='text-20 text-md-start text-sm-center'>
                                        The Simplest and Fastest Way to Secure Your Files Online
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} xs={12} lg={6} md={6}>
                                <FormGroup className="mb-3" controlid="formBasicEmail">
                                    <Input type="text" placeholder="Username"
                                           onChange={(evt) => this.updateUsername(evt.target.value)}/>
                                </FormGroup>
                            </Col>
                            <Col sm={12} xs={12} lg={6} md={6}>
                                <FormGroup className="mb-3" controlid="formBasicEmail">
                                    <Input type="password" placeholder="Password"
                                           onChange={(evt) => this.updatePassword(evt.target.value)}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className='d-flex justify-content-end'>
                            {/** Will be displayed on desktop web. */}
                            <Col sm={12} xs={12} md={6} lg={6} className='d-flex justify-content-start d-none d-md-block d-lg-block '>
                                Forgot your password? Click <a href={'#'} onClick={() => {this.togglePasswordResetModal(true)}}>here</a> to reset it.
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} className='d-flex justify-content-end'>
                                <KripteraButton label={'Login'} disabled={isLoginButtonDisabled} isLoading={isLoading} onClick={this.login}/>
                            </Col>
                            {/** Will be displayed on mobile web. */}
                            <Col sm={12} xs={12} md={0} lg={0} className='d-md-none d-lg-block d-lg-none d-xl-block d-xl-none mt-3'>
                                Forgot your password? Click <a href={'#'} onClick={() => {this.togglePasswordResetModal(true)}}>here</a> to reset it.
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <PasswordResetModal isOpen={displayPasswordResetModal} toggle={() => {this.togglePasswordResetModal(false)}}/>
                <Modal isOpen={displayRegModal} toggle={() => this.toggleRegModal(false)} centered>
                    <ModalHeader >Thank you for registering!</ModalHeader>
                    <ModalBody>
                        A confirmation email has been sent to you. Your account will be activated when you have confirmed your email.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => this.toggleRegModal(false)}>Close</Button>
                    </ModalFooter>
                </Modal>
            </Container>
        );
    }
}

export default withRouter(Login);