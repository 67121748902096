import React, {useEffect, useState} from "react";
import {contactsService} from "../../MainLayout/MainLayout";
import Select from "react-select";

export const ContactsDropdown = ({className, onSelect, existingSubscribers, selectedContacts}) => {
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        getContacts();
    }, [contacts.length]);

    useEffect(() => {
        getContacts();
    }, [existingSubscribers.length]);

    const getContacts = () => {
        contactsService.getContactsSingleRequest().then((contacts) => {
            if (contacts.existing) {
                contacts.existing = contacts.existing.filter((contact) => {
                    if (existingSubscribers && existingSubscribers.length > 0) {
                        const exists = existingSubscribers.find((subscriber) => subscriber.email.toLowerCase() === contact.email.toLowerCase());
                        return exists === undefined;
                    }
                    return true;
                });
                setContacts(contacts.existing);
            }
        });
    };

    return (
        <Select isMulti className={className}
                options={convertContactsToSelectItems(contacts)}
                value={selectedContacts}
                onChange={(selection) => {
                    if (selection) {
                        onSelect(selection);
                        setContacts([]);
                    } else {
                        onSelect([]);
                    }
                }}
        />
    );
};

const convertContactsToSelectItems = (contacts) => {
    return contacts.map((contact) => {
        return {label: contact.email, value: contact.email};
    });
};