import {Crypt} from 'hybrid-crypto-js';
import {KRIPTERA_EXTENSION} from "../../Constants/GlobalConstants";
import {store} from "../../Store/store";

export default class EncryptionService {

    encrypt = ( publicKey, file ) => {
        return new Promise( ( resolve, reject ) => {
            const reader = new FileReader();
            reader.onload = () => {
                try {
                    const crypt = new Crypt();
                    const encrypted = crypt.encrypt( publicKey, reader.result );
                    resolve( encrypted );
                } catch ( e ) {
                    reject( e );
                }
            };
            reader.readAsDataURL( file );
        } );
    };

    decrypt = ( file ) => {
        return new Promise( ( resolve, reject ) => {
            const reader = new FileReader();
            reader.onload = () => {
                if ( this.isJSON( reader.result ) ) {
                    console.log('store.getState().user.prKt',store.getState().user.prK);
                    try {
                        const privateKey = store.getState().user.prK;
                        const crypt = new Crypt();
                        const decrypted = crypt.decrypt( privateKey, reader.result );
                        this.srcToFile( decrypted.message, file.name.replaceAll( KRIPTERA_EXTENSION, '' ), 'octet-stream' ).then( ( file ) => {
                            resolve( file );
                        } );
                    } catch ( e ) {
                        reject( e );
                    }
                } else {
                    reject({code: 1001, message: 'Not a Kriptera file'});
                }
            };
            reader.readAsText( file );
        } );
    };

    srcToFile = ( src, fileName, mimeType ) => {
        return (fetch( src )
                .then( function ( res ) {
                    return res.arrayBuffer();
                } )
                .then( function ( buf ) {
                    return new File( [buf], fileName, {type: mimeType} );
                } )
        );
    }


    isJSON = ( input ) => {
        try {
            JSON.parse( input );
            return true;
        } catch ( e ) {
            return false;
        }
    };
}
