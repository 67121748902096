import {Subject} from 'rxjs';
export const RESET_CONTACT_LIST = 'RESET_CONTACT_LIST';

export default class ActionsPubSub {
    actionsSubject = new Subject();

    postAction = (type, payload) => {
        this.actionsSubject.next({type: type, payload: payload});
    };

    getAction = () => {
        return this.actionsSubject;
    };
}