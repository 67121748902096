import HttpRefreshToken from "../Http/HttpRefreshToken";
import {Subject} from "rxjs";
import {USER_KEYS_ACTION_TYPES} from "../../Constants/GlobalConstants";
import {store} from "../../Store/store";
import {receiveKeysApi, receiveKeysKriptera} from "../../Store/Actions/UserActions";

export default class UserService {
    http = new HttpRefreshToken();
    keysAction = new Subject();
    puK = null;
    prK = null;

    constructor() {
        this.http = new HttpRefreshToken();
    }

    getKeysActions = () => {
        return this.keysAction;
    };

    initUserKeyGeneration = async () => {
        const hasPersonalKeys = await this.checkForPersonalKeys();

        if (hasPersonalKeys) {
            this.keysAction.next({type: USER_KEYS_ACTION_TYPES.AVAILABLE, payload: {}});
        } else {
            this.keysAction.next({type: USER_KEYS_ACTION_TYPES.GENERATING, payload: {}});
            this.generateKeys().then((keys) => {
                this.http.pushKeys(keys).then(() => {
                    // Save the keys to local storage using redux after generating them
                    store.dispatch(receiveKeysKriptera({puK: keys.pub, prK: keys.priv}));

                    this.keysAction.next({type: USER_KEYS_ACTION_TYPES.GENERATED, payload: {}});
                });
            });
        }
    };

    checkForPersonalKeys = async () => {
        let keyPair = null;

        try {
            keyPair = await this.getPersonalKeys();
        } catch (e) {
            return false;
        }
        return  keyPair !== undefined && keyPair !== null;
    };

    getPersonalKeys = () => {
        return this.http.getPersonalKeys().then((keyPair) => {
            this.puK = keyPair.puK;
            this.prK = keyPair.prK;

            // Save the keys to local storage using redux after receiving them
            store.dispatch(receiveKeysApi({puK: keyPair.puK, prK: keyPair.prK}));

            return keyPair;
        });
    };

    generateKeys = () => {
        return new Promise((resolve, reject) => {
            window.crypto.subtle.generateKey(
                {
                    name: "RSA-PSS",
                    // Consider using a 4096-bit key for systems that require long-term security
                    modulusLength: 2048,
                    publicExponent: new Uint8Array([1, 0, 1]),
                    hash: "SHA-256",
                },
                true,
                ["sign", "verify"]
            ).then(async (keyPair) => {
                const privateKey = await this.exportKey(keyPair.privateKey, true);
                const publicKey = await this.exportKey(keyPair.publicKey, false);
                const keys = {pub: publicKey, priv: privateKey};
                resolve(keys)
            }).catch((e) => {
                reject(e);
            });
        })

    };

    exportKey = async (key, isPrivate) => {
        const exported = await window.crypto.subtle.exportKey(
            isPrivate ? "pkcs8" : "spki",
            key
        );
        const exportedAsString = this.ab2str(exported);
        const exportedAsBase64 = window.btoa(exportedAsString);
        const exportedKey = isPrivate ? `-----BEGIN PRIVATE KEY-----\n${exportedAsBase64}\n-----END PRIVATE KEY-----` :
            `-----BEGIN PUBLIC KEY-----\n${exportedAsBase64}\n-----END PUBLIC KEY-----`;
        return exportedKey;
    };

    ab2str = (arrayBuff) => {
        return String.fromCharCode.apply(null, new Uint8Array(arrayBuff));
    };
}