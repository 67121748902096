import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import KripteraButton from '../../../Partials/KripteraButton/KripteraButton';

export default class ConfirmationModal extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {isOpen, confirmAction, confirmButton, declineButton, message, title} = this.props;
        return (
            <Modal isOpen={isOpen} toggle={() => {
                confirmAction(false)
            }} centered>
                <ModalHeader>{title}</ModalHeader>
                <ModalBody>
                    {message}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => confirmAction(false)}>{declineButton}</Button>
                    <KripteraButton label={confirmButton} disabled={false} onClick={() => confirmAction(true)} />
                </ModalFooter>
            </Modal>
        );
    }
}