import { RECEIVE_KEYS_API, RECEIVE_KEYS_KRIPTERA, SAVE_USER, UPDATE_TOKEN } from "../Actions/UserActions";
import {LOGOUT} from "../Actions/GeneralActions";

const initialState = {
    info: null,
    prK: null,
    puK: null
}

const UserReducer = (state = initialState, action) => {
    if (action.type === SAVE_USER) {
        return Object.assign({}, state, {
            info: action.payload
        });
    }

    if (action.type === UPDATE_TOKEN) {
        return Object.assign({}, state, {
            info: {
                ...state.info,
                token: action.payload
            }
        });
    }

    if (action.type === LOGOUT) {
        return Object.assign({}, initialState);
    }

    if (action.type === RECEIVE_KEYS_KRIPTERA) {
        return Object.assign({}, state, {
            prK: action.payload.prK,
            puK: action.payload.puK
        });
    }

    if (action.type === RECEIVE_KEYS_API) {
        return Object.assign({}, state, {
            prK: action.payload.prK.data.privatekey,
            puK: action.payload.puK.data.publickey
        });
    }


    return state;
};

export default UserReducer;
