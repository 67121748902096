import React from 'react';
import {Button, Col, Container, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import KripteraButton from '../../../Partials/KripteraButton/KripteraButton';
import ContactsService from "../../../Services/Contacts/ContactsService";
//import {toast} from '../../../../node_modules/react-toastify';
import {toast} from "react-toastify";
import { isEmailValid } from "../../../helpers/helpers";
import HttpRefreshToken from "../../../Services/Http/HttpRefreshToken";
import { store } from "../../../Store/store";
import { WARNING_MESSAGES, ERROR_MESSAGES } from "../../../Constants/GlobalConstants";
export default class AddContactModal extends React.Component {
    http = new HttpRefreshToken()
    freeContactsLimit = 1;
    contactsService = new ContactsService();

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            isLoading: false
        }
    }

    updateEmail = (email) => {
        this.setState({
            email: email
        });
    };

    toggleLoading = (toggle) => {
        this.setState({
            isLoading: toggle
        })
    };

    isContactsAmountExceeded = () => {
        const contactReplaceCase = "invite";
        const { contacts, replaceContact, closeAction, contactsLimitModal } = this.props;
        const { subscription } = store.getState().user.info;

        if (this.contactsService.isContactsLimitExceeded(contacts, subscription.type, contactReplaceCase)) {
            if (subscription.type === "free") {
                replaceContact(this.state.email, contactReplaceCase);
                closeAction();

                return true;
            } else {
                contactsLimitModal(true);
                closeAction();

                return true;
            }
        }
    }

    addContact = async () => {
        const {email} = this.state;
        if (store.getState().user.info.email === email) {
            toast.warn(WARNING_MESSAGES["ADD_CONTACT_ME"]);
            return;
        }

        if (this.isContactsAmountExceeded()) return;

        const {contactAdded} = this.props;

        this.toggleLoading(true);
        try {
            await this.contactsService.inviteContact(email)
            this.setState({email: ""});
            contactAdded()
        } catch(error) {
            console.error(error)
            toast.error(ERROR_MESSAGES["ADD_CONTACT"]);
            this.toggleLoading(false);
        }

        this.toggleLoading(false);
    };

    render() {
        const {isOpen, closeAction} = this.props;
        const {isLoading} = this.state;
        const isAddContactButtonDisabled = this.state.email && isEmailValid(this.state.email) ? false : true;

        return (
            <Modal isOpen={isOpen} toggle={() => {
                closeAction()
            }} centered>
                <ModalHeader>Add contact</ModalHeader>
                <ModalBody>
                    <Container>
                        <Row className='mb-2'>
                            <Col>
                                To add a contact input the user's email
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3" controlid="formBasicEmail">
                                    <Input type="text" placeholder="Enter user's email"
                                           onChange={(evt) => this.updateEmail(evt.target.value)}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => closeAction()}>Close</Button>
                    <KripteraButton
                        label={"Add contact"}
                        disabled={isAddContactButtonDisabled}
                        isLoading={isLoading}
                        onClick={this.addContact}/>
                </ModalFooter>
            </Modal>
        );
    }
}
