import {BehaviorSubject, Subscription} from "rxjs";
import HttpRefreshToken from "../Http/HttpRefreshToken";
import GlobalService from "../Global/GlobalService";

export const CONTACT_TYPES = {
    existing: 'existing',
    sent: 'sent',
    pending: 'pending'
};

export default class ContactsService {
    intervalRequest = 0;
    contactsSubject = new BehaviorSubject({existing: [], pending: [], sent: []});
    subscription = new Subscription();
    http = new HttpRefreshToken();
    globalService = new GlobalService();

    requestContacts() {
        this.http.getContactList().then((response) => {
            const contactList = this.remapContacts(JSON.parse(response.data.contacts), CONTACT_TYPES.existing);
            const pendingList = this.remapContacts(JSON.parse(response.data.pending), CONTACT_TYPES.pending);
            const sentList = this.remapContacts(JSON.parse(response.data.sent), CONTACT_TYPES.sent);
            this.contactsSubject.next({existing: contactList, pending: pendingList, sent: sentList});
        }).catch((e) => {
            console.log( 'e', e );
        })
    }

    getContactsSingleRequest = () => {
        return this.http.getContactList().then((response) => {
            const contactList = this.remapContacts(JSON.parse(response.data.contacts), CONTACT_TYPES.existing);
            const pendingList = this.remapContacts(JSON.parse(response.data.pending), CONTACT_TYPES.pending);
            const sentList = this.remapContacts(JSON.parse(response.data.sent), CONTACT_TYPES.sent);
            return {existing: contactList, pending: pendingList, sent: sentList};
        }).catch((e) => {
            console.log( 'e', e );
            return {};
        })
    };

    remapContacts = (contacts, type) => {
        return contacts.map((contact) => {
            contact.firstname = this.globalService.upperCase(contact.firstname);
            contact.lastname = this.globalService.upperCase(contact.lastname);
            contact.letterFirstname = this.globalService.extractFirstLetter(contact.firstname);
            contact.letterLastname = this.globalService.extractFirstLetter(contact.lastname);
            contact.type = type;
            return contact;
        });
    };

    declineContactRequest = (email) => {
      return this.http.declineRequestedContactRequest(email);
    };

    acceptContactRequest = (email) => {
        return this.http.acceptContactRequest(email);
    };

    declineContactInvite = (email) => {
        return this.http.declineContactRequest(email);
    };

    inviteContact = (email) => {
        return this.http.inviteContact(email);
    };

    deleteContact = (email) => {
        return this.http.deleteContact(email);
    };

    getContactList = (interval) => {
        if (interval) {
            this.requestContacts();
            this.intervalRequest = setInterval(() => {
                this.requestContacts();
            }, interval)
        } else {
            this.requestContacts();
        }

    };

    refreshContactList = () => {
        this.requestContacts();
    };

    clear = () => {
        clearInterval(this.intervalRequest);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    isContactsLimitExceeded = (contacts, subscriptionType) => {
        if (subscriptionType === "enterprise") return false
        const contactsLimit = this.constructContactsLimit(subscriptionType);
        const contactsEmails = this.extractAllEmailFromContacts(contacts);

        return contactsEmails.length === contactsLimit ? true : false;
    };

    extractAllEmailFromContacts = (contacts) => {
        if (contacts) {
            const { existing } = contacts;
            if (existing && existing.length) {
                return existing.map(contact => contact.email);
            }
        }

        return [];
    }

    extractExistingContact = (existing) => {
        if (existing && existing.length) return existing.map(contact => contact.email);

        return [];
    }

    constructContactsLimit = (subscriptionType) => {
        let contactsLimitFree = 1;
        let contactsLimitSolo = 10;
        let contactsLimitBusiness = 30;

        if (subscriptionType === "free") return contactsLimitFree
        else if (subscriptionType === "solo") return contactsLimitSolo
        else if (subscriptionType === "business") return contactsLimitBusiness;
    };
}
