import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {googleDriveService} from "../../MainLayout/MainLayout";

export const GoogleLoginModal = ({display, onModalClose}) => {

    return (
        <Modal isOpen={false} toggle={() => onModalClose()}>
            {/*<ModalHeader toggle={() => onModalClose()}>Modal title</ModalHeader>*/}
            <ModalBody>
                <p>Hi there! We have an amazing feature which allows for the file upload to your personal Google drive account after the encryption of your file has finished.</p>
                <p>To use this feature just go to your profile and login with your Google account.</p>
                <p>
                </p>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => onModalClose()}>Do Something</Button>{' '}
                <Button color="secondary" onClick={() => onModalClose()}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};
