import {USER_TOKEN} from "../../App";

export const SAVE_USER = '[USER ACTION] SAVE_USER';
export const GET_KEYS = '[USER ACTION] GET_KEYS';
export const RECEIVE_KEYS_API = '[USER ACTION] RECEIVE_KEYS_API';
export const UPDATE_TOKEN = '[USER ACTION] UPDATE_TOKEN';
export const RECEIVE_KEYS_KRIPTERA = '[USER ACTION] RECEIVE_KEYS_KRIPTERA';

export const saveUser = (payload) => {
    return {
        type: SAVE_USER,
        payload: payload
    }
};

export const updateToken = (payload) => {
    return {
        type: UPDATE_TOKEN,
        payload: payload
    }
};

export const getKeys = (payload) => {
    return {
        type: GET_KEYS,
        payload: payload
    }
};

export const receiveKeysKriptera = (payload) => {
    return {
        type: RECEIVE_KEYS_KRIPTERA,
        payload: payload
    }
};

export const receiveKeysApi = (payload) => {
    return {
        type: RECEIVE_KEYS_API,
        payload: payload
    }
};