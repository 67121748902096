import "./App.scss";
import React, {useEffect} from "react";
import Login from "./Screens/Login/LoginComponent";
import Register from "./Screens/Register/RegisterComponent";
import PrivateRoute from "./Routing/PrivateRoute";
import MainLayout from "./Screens/MainLayout/MainLayout";
import { Route, Switch } from "react-router-dom";

const App = () => {
  return (
    <Switch>
      <Route path={["/login/:status", "/login"]}>
        <Login />
      </Route>
      <Route path="/register">
        <Register />
      </Route>
      <PrivateRoute path="/" component={MainLayout} />
    </Switch>
  );
};

export default App;
