export default class ValidationModel {
    isFirstNameEmpty = false;
    isLastNameEmpty = false
    isEmailEmpty = false;
    isEmailConfirmEmpty = false;
    isPasswordEmpty = false;
    isPasswordConfirmEmpty = false;
    isEmailMismatch = false;
    isPasswordMismatch = false;
    isEmailInvalid = false;
    isPasswordNotComplex = false;
    errorMessage = '';
    isInvalid = this.errorMessage && this.errorMessage.length > 0;


    validate = () => {

        if (this.isFirstNameEmpty || this.isLastNameEmpty
            || this.isEmailEmpty || this.isEmailConfirmEmpty
            || this.isPasswordEmpty || this.isPasswordConfirmEmpty) {
            this.errorMessage = 'The fields cannot be left empty';
        } else if (this.isPasswordMismatch || this.isEmailMismatch) {
            this.errorMessage = 'The fields mismatch';
        } else if (this.isEmailInvalid) {
            this.errorMessage = 'The email that you have entered is invalid.';
        } else if (this.isPasswordNotComplex) {
            this.errorMessage = 'The password must contain at least one capital letter, digit and a length of minimum 8 and maximum 32 characters.';
        }

        this.isInvalid = this.errorMessage.length > 0;
    };
}