import React, {useState} from "react";
import "./SubscriptionContact.scss";
import {service} from "../../Modals/SettingsModal/SettingsModal";
import KripteraButton from "../../../Partials/KripteraButton/KripteraButton";
import {RemoveContact} from "./RemoveContact";

export const SubscribedContacts = ({subscribers, refreshSubscribers}) => {
    const [loading, setLoading] = useState({
        0: false,
    });

    const toggleLoading = (toggle, buttonIndex) => {
        setLoading((prevState) => {
            return {
                ...prevState,
                [buttonIndex]: toggle,
            };
        });
    };

    const remove = (email, buttonIndex) => {
        toggleLoading(true, buttonIndex);

        removeSubscriber(email, service, () => {
            toggleLoading(false, buttonIndex);
            refreshSubscribers();
        });
    };

    return (
        <div className="d-flex flex-column overflow-auto" style={{maxHeight: "100px"}}>
            {subscribers.map((account, index) => {
                return (
                    <div key={index} className="d-flex px-2 flex-row align-items-center mb-2 justify-content-between">
                        <div className="account-sub">{account.email}</div>
                        <RemoveContact remove={() => {
                            remove(account.email, index)
                        }}/>
                    </div>
                );
            })}
        </div>
    );
};

const removeSubscriber = (email, service, callback) => {
    service
        .removeSubscriber(email)
        .then(() => {
            callback();
        })
        .catch((e) => {
            callback();
        });
};
