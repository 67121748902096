import axios from 'axios';
import {store} from "../../Store/store";
import {saveUser} from "../../Store/Actions/UserActions";
import {setRefreshToken, setToken} from "../../global";

export default class Http {
    instance = null;

    constructor() {
        this.instance = this.createInstance();
    }

    createInstance = (headers) => {
        return axios.create( {
            baseURL: process.env.REACT_APP_API_BASE_URL,
            timeout: 30000,
            headers: headers ? { ...headers } : {}
        } )
    };

    register( regModel ) {
        return this.instance.post( '/register', {
            username: regModel.email,
            email: regModel.email,
            password: regModel.password,
            firstName: regModel.firstname,
            lastName: regModel.lastname
        } );
    }

    resetPassword = (email) => {
        return this.instance.post( '/user/sendresetpassword', {
           email: email
        });
    };

    //With dispatch on response
    login = (username, password) => {
        return this.instance.post('/auth', {
            email: username,
            username: username,
            password: password
        }).then((response) => {
            const user = response.data;
            setToken(user.token);
            setRefreshToken(user.refresh_token);
            user.email = username;
            store.dispatch(saveUser(user));
            return response;
        });
    };

}
