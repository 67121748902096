import {createStore, combineReducers, applyMiddleware} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import createMigrate from "redux-persist/es/createMigrate"; // defaults to localStorage for web and AsyncStorage for react-native
import thunk from 'redux-thunk';
import UserReducer from "./Reducers/UserReducer";

const persistConfig = {
	key: 'root',
	storage,
	version: 1,
	migrate: createMigrate({}, { debug: true }),
};

const rootReducer = combineReducers({
	user: UserReducer,
	keys: null
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(thunk));

const persistor = persistStore(store);

export { store, persistor };
