import React from 'react';
import {Switch} from "react-router-dom";
import './MainLayout.scss';
import NavbarMain from "../Global/NavbarMain/NavbarMain";
import BottomMainTabs from "../Global/BottomMainTabs/BottomMainTabs";
import {Route} from "react-router";
import ContactList from "../ContactList/ContactList";
import FloatingActionButton from "../Global/FloatingActionButton/FloatingActionButton";
import AddContactModal from "../Modals/AddContactModal/AddContactModal";
import ActionsPubSub from "../../Services/ActionsPubSub/ActionsPubSub";
import ContactsService from "../../Services/Contacts/ContactsService";
import UserService from "../../Services/User/UserService";
import {toast} from "react-toastify";
import {
    FILE_OPERATIONS,
    KRIPTERA_EXTENSION,
    TEMP_KEY_MESSAGES,
    USER_KEYS_ACTION_TYPES
} from "../../Constants/GlobalConstants";
import {GoogleLoginModal} from "../Global/GoogleLoginModal/GoogleLoginModal";
import GoogleDriveService from "../../Services/GoogleDrive/GoogleDriveService";
import EncryptionService from "../../Services/EncryptionService/EncryptionService";
import { store } from "../../Store/store";
import HttpRefreshToken from "../../Services/Http/HttpRefreshToken";
import FileService from "../../Services/FileService/FileService";
import {FilesList} from "../Files/FilesList";
import FileHistoryService from "../../Services/FileHistoryService/FileHistoryService";
import SessionTimeoutModel from '../Global/SessionTimeout/SessionTimeoutModal';
import { displayReplaceContactMessage, exceededFileSizeLimitMessage, ERROR_MESSAGES, CONTACTS_TO_LIST, CRYPTING_IN_PROCESS } from "../../Constants/GlobalConstants";
import ConfirmationModal from "../Modals/ConfirmationModal/ConfirmationModal";
import {excludeFilesExceedingLimits, isFileSizeExceedLimit} from "../../helpers/helpers";
import FileExceedSizeModal from "../Modals/FileExceedSizeModal/FileExceedSizeModal";
import ContactsLimitModal from "../Modals/ContactsLimitModal/ContactsLimitModal";
import ProgressBarCryption from '../../Partials/ProgressBar/ProgressBarCryption';
import Footer from '../Footer/Footer';
import JSZip from "jszip";
import FileSaver from "file-saver";

export const actionsPubSub = new ActionsPubSub();
export let contactsService = new ContactsService();
export let userService = new UserService();
export let googleDriveService = new GoogleDriveService();

export default class MainLayout extends React.Component {
    uploadDecrypt = null;
    uploadEncryptForMyself = null;
    fileService = new FileService();
    fileHistoryService = new FileHistoryService();
    http = new HttpRefreshToken();
    subscriptionType = null;
    labelCrypting = React.createRef();
    replaceContactCase = React.createRef();

    constructor(props) {
        super(props);
        contactsService = new ContactsService();
        userService = new UserService();
        this.subscriptionType = store.getState().user.info.subscription.type;

        this.state = {
            contacts: null,
            addContactInputEmail: "",
            isLoading: false,
            displayAddContact: false,
            displayEncryptFile: false,
            displayGoogleLoginModal: true,
            displayReplaceContact: false,
            displayContactsLimitModal: false,
            displayFileExceedSize: false
        }
    }

    componentDidMount() {
        userService.initUserKeyGeneration();
        userService.getKeysActions().subscribe((action) => {
            // Inform the user about the key generation process
            if (action.type === USER_KEYS_ACTION_TYPES.GENERATING) {
                toast.warning(TEMP_KEY_MESSAGES[action.type]);
            } else if (action.type === USER_KEYS_ACTION_TYPES.GENERATED) {
                toast.success(TEMP_KEY_MESSAGES[action.type]);
            }
        })
        
        this.getContacts().then();
        this.preventRefresh();
    }
    
    preventRefresh() {
        window.onbeforeunload = function() {
            return "Upon refresh you will be automatically logged out for security reasons. Are you sure you want to proceed?";
        }
    }

    componentDidUpdate(prevProps,prevState) {
        if (this.state.contacts === prevState.contacts) {
            this.getContacts().then();
        }
    }

    getContacts = () => {
        return this.http.getContactList().then((response) => {
            this.setState({
                contacts: response.data
            })
        })
    };

    handleInputEmail = (email, replaceContactCase) => {
        this.replaceContactCase.current = replaceContactCase;
        this.setState({ addContactInputEmail: email });
    };

    toggleGoogleLoginModal = (toggle) => {
        this.setState({
            displayGoogleLoginModal: toggle
        })
    };

    toggleAddContactModal = (toggle) => {
        this.setState({
            displayAddContact: toggle
        })
    };

    toggleEncryptFileModal = (toggle) => {
        this.setState({
            displayEncryptFile: toggle
        })
    };

    toggleReplaceContactModal = (toggle) => {
        this.setState({
            displayReplaceContact: toggle
        })
    };

    toggleContactsLimitModal = (toggle) => {
        this.setState({
            displayContactsLimitModal: toggle
        })
    };

    toggleFileExceedSize = (toggle) => {
        this.setState({
            displayFileExceedSize: toggle
        })
    };

    toggleIsLoading = (toggle) => {
        this.setState({
            isLoading: toggle
        })
    };

    contactList = () => {
        if (this.state.contacts) {
            const { contacts, sent, pending } = this.state.contacts;
            const contactsArray = JSON.parse(contacts);
            const sentArray = JSON.parse(sent);
            const pendingArray = JSON.parse(pending);
            let contactList = null;

            if (contactsArray && contactsArray.length) {
                contactList = CONTACTS_TO_LIST["contacts"];

                return contactList;
            }

            if (sentArray && sentArray.length) {
                contactList = CONTACTS_TO_LIST["sent"];

                return contactList;
            }

            if (pendingArray && pendingArray.length) {
                contactList = CONTACTS_TO_LIST["pending"];

                return contactList;
            }

        }
    }

    removeContact = async (contacts, pending, sent) => {
        if (contacts.length) {
            try {
                return await this.http.deleteContact(contacts[0].email)
            } catch(error) {
                console.error(error);
            }
        }

        if (pending.length) {
            try {
                return await this.http.declineRequestedContactRequest(pending[0].email)
            } catch(error) {
                console.error(error);
            }
        }

        if (sent.length) {
            try {
                return await this.http.declineContactRequest(sent[0].email)
            } catch(error) {
                console.error(error);
            }
        }
    };

    handleReplaceContact = async (isConfirmed) => {
        if (isConfirmed) {
            const contacts = JSON.parse(this.state.contacts.contacts);
            const pending = JSON.parse(this.state.contacts.pending);
            const sent = JSON.parse(this.state.contacts.sent);

            if (this.replaceContactCase.current === "invite") {
                try {
                    await this.removeContact(contacts, pending, sent);
                    await this.http.inviteContact(this.state.addContactInputEmail);
                    await contactsService.refreshContactList();
                } catch(error) {
                    toast.error(ERROR_MESSAGES["REPLACE_CONTACT"]);
                    console.error(error);
                }
            } else if (this.replaceContactCase.current === "accept") {
                try {
                    await this.http.acceptContactRequest(this.state.addContactInputEmail);
                    await contactsService.refreshContactList();
                } catch(error) {
                    toast.error(ERROR_MESSAGES["REPLACE_CONTACT"]);
                    console.error(error);
                }
            }
        }
        this.toggleReplaceContactModal(false);

    };

    onChangeFileDecrypt = async (event) => {
        const { isLoading } = this.state;
        const zip = new JSZip();
        
        if (isLoading) {
            toast.error(CRYPTING_IN_PROCESS);
            return;
        }

        this.toggleIsLoading(true);
        let files = [];
        const service = new EncryptionService();

        for (let i = 0; i < event.target.files.length; i++) {
            files.push(event.target.files[i]);
        }
        files = excludeFilesExceedingLimits(files, this.subscriptionType, FILE_OPERATIONS.ENCRYPT)
        
        if (files.length > 0) {
            for (const file of files) {
                try {
                    this.labelCrypting.current = `Decrypting ${file.name}`;
                    const decryptedContent = await service.decrypt(file);
                    await zip.file(`${decryptedContent.name}`, decryptedContent);
                    this.fileHistoryService.pushFile(store.getState().user.info.email, files[0], FILE_OPERATIONS.DECRYPT);
                } catch (error) {
                    console.warn(error);
                    toast.error(ERROR_MESSAGES.ERROR_DECRYPT);
                }
            }
            zip.generateAsync({type: 'blob'}).then((content) => {
                FileSaver.saveAs(content, 'decrypted.zip');
            });
        } else {
            try {
                this.labelCrypting.current = `Decrypting ${files[0].name}`;
                const decryptedContent = await service.decrypt(files[0]);
                this.fileService.downloadDecrypted(decryptedContent, decryptedContent.name);
                this.fileHistoryService.pushFile(store.getState().user.info.email, files[0], FILE_OPERATIONS.DECRYPT);
            } catch (error) {
                console.warn(error);
                toast.error(ERROR_MESSAGES.ERROR_DECRYPT);
            }
        }




        this.toggleIsLoading(false);
        event.target.value = null;
    }

    onChangeFileEncryptForMyself = async (event) => {
        const { isLoading } = this.state;
        const zip = new JSZip();

        if (isLoading) {
            toast.error(CRYPTING_IN_PROCESS);
            return;
        }

        this.toggleIsLoading(true);
        const isMyFile = true
        let files = [];
        const service = new EncryptionService();

        for (let i = 0; i < event.target.files.length; i++) {
            files.push(event.target.files[i]);
        }
        files = excludeFilesExceedingLimits(files, this.subscriptionType, FILE_OPERATIONS.ENCRYPT, () => {
            toast.error('There were files that had exceeded the encryption limit. They will be excluded.')
        })

        const publicKey = await this.http.getContactPublicKey(store.getState().user.info.email);

        for ( const file of files) {
            this.labelCrypting.current = `Encryptiing ${file.name}`;
            try {
                const encryptedContent = await service.encrypt(publicKey, file);
                let blob = new Blob([encryptedContent], {type: "text/plain;charset=utf-8"});
                await zip.file(`${file.name}${KRIPTERA_EXTENSION}`, blob);
                this.fileHistoryService.pushFile(store.getState().user.info.email, file, FILE_OPERATIONS.ENCRYPT);
            } catch (error) {
                console.warn(error);
                toast.error(ERROR_MESSAGES.ERROR_ENCRYPT);
            }
        }
        zip.generateAsync({type: 'blob'}).then((content) => {
            FileSaver.saveAs(content, 'encrypted.zip');
        });

        this.toggleIsLoading(false);
    }

    render() {
        const { contacts, displayAddContact, displayGoogleLoginModal, displayReplaceContact, displayContactsLimitModal, displayFileExceedSize, isLoading} = this.state;
        const newContacts = {
            existing: contacts ? JSON.parse(contacts.contacts) : null,
            pending: contacts ? JSON.parse(contacts.pending) : null,
            sent: contacts ? JSON.parse(contacts.sent) : null
        };

        return (
            <>
                <div className='main-layout'>
                {/* Displayed only in desktop resolution */}
                <NavbarMain/>
                <ProgressBarCryption isLoading={isLoading} label={this.labelCrypting?.current} />
                    <div className='main-background contacts-container fill container-fluid'>
                        <div className='fill container-fluid'>
                            <Switch>
                                <Route path='/contacts'>
                                    <ContactList
                                        toggleIsLoading={this.toggleIsLoading}
                                        isLoading={isLoading}
                                        labelCrypting={this.labelCrypting}
                                        replaceContact={(email, replaceContactCase) => {
                                            this.handleInputEmail(email, replaceContactCase);
                                            this.toggleReplaceContactModal(true)
                                        }}
                                        contactsLimitModal={(toggle) => this.toggleContactsLimitModal(toggle)}
                                    />
                                </Route>
                                <Route path='/files' component={FilesList}/>
                            </Switch>
                        </div>
                    </div>
                    {/* Displayed only in desktop resolution */}
                    <FloatingActionButton onEncryptFile={() => this.uploadEncryptForMyself.click()} onAddContact={() => this.toggleAddContactModal(true)} onDecryptFile={() =>  this.uploadDecrypt.click()}/>
                    {/*Displayed only in mobile resolution*/}
                    <BottomMainTabs onAddContact={() => this.toggleAddContactModal(true)} onDecryptFile={() => this.uploadDecrypt.click()} onEncryptFile={() => this.uploadEncryptForMyself.click()}/>
                    <AddContactModal
                        contacts={newContacts}
                        contactAdded={() => {
                            contactsService.refreshContactList();
                            this.toggleAddContactModal(false);
                        }}
                        isOpen={displayAddContact}
                        closeAction={() => this.toggleAddContactModal(false) }
                        replaceContact={(email, contactReplaceCase) => {
                            this.handleInputEmail(email, contactReplaceCase);
                            this.toggleReplaceContactModal(true)
                        }}
                        contactsLimitModal={(toggle) => this.toggleContactsLimitModal(toggle)}
                    />
                    <ConfirmationModal
                        isOpen={displayReplaceContact}
                        title={"Replace contact"}
                        message={displayReplaceContactMessage(contactsService.extractExistingContact(newContacts.existing), this.contactList())}
                        confirmButton={'Yes'} declineButton={"No"}
                        confirmAction={(isConfirmed) => this.handleReplaceContact(isConfirmed)}
                        />
                    <ContactsLimitModal
                        isOpen={displayContactsLimitModal}
                        confirmAction={(isConfirmed) => this.toggleContactsLimitModal(isConfirmed)}
                    />
                    <FileExceedSizeModal
                        isOpen={displayFileExceedSize}
                        title={"File exceeded"}
                        message={exceededFileSizeLimitMessage(this.subscriptionType)}
                        confirmButton={'OK'}
                        confirmAction={(isConfirmed) => this.toggleFileExceedSize(isConfirmed)}
                        subscriptionType={this.subscriptionType}
                    />
                    <GoogleLoginModal display={displayGoogleLoginModal} onModalClose={() => this.toggleGoogleLoginModal(false)}/>
                    <SessionTimeoutModel/>
                    {/*Hidden file decrypt input*/}
                    <input id="myInput"
                        type="file"
                        multiple
                        ref={(ref) => this.uploadDecrypt = ref}
                        style={{display: 'none'}}
                        onClick={(event) => {
                            event.target.value = null
                        }}
                        onChange={this.onChangeFileDecrypt.bind(this)}
                    />
                    {/*Hidden file encrypt for myself input*/}
                    <input id="myInput-encrypt-for-myself"
                        type="file" 
                        multiple
                        ref={(ref) => this.uploadEncryptForMyself = ref}
                        style={{display: 'none'}}
                        onClick={(event) => {
                            event.target.value = null
                        }}
                        onChange={this.onChangeFileEncryptForMyself.bind(this)}
                    />
                </div>
                <Footer />
            </>
        );
    }
}
