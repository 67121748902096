import React from 'react';
import './TopMainTabs.scss';
import {withRouter} from "react-router";

class TopMainTabs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTab: window.location.pathname
        }
    }

    setTabState = (tab, callback) => {
        this.setState({
            currentTab: tab
        }, callback);
    };

    render() {
        const {currentTab} = this.state;

        return (
           <div>
               <div className={`d-md-flex d-lg-flex flex-row justify-content-between d-none tab-container`}>
                   <div className={`tab-top ${currentTab.includes('contacts') ? 'selected' : ''}`} onClick={() =>{
                       this.setTabState('contacts', () => {
                           this.props.history.push('/contacts');
                       })
                   }}>
                       CONTACTS
                   </div>
                   <div className={`tab-top ${currentTab.includes('files') ? 'selected' : ''}`} onClick={() => {
                       this.setTabState('/files', () => {
                           this.props.history.push('/files')
                       })
                   }}>
                       FILE HISTORY
                   </div>
               </div>
           </div>
        )
    }
}

export default withRouter(TopMainTabs);