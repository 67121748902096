import React, {useEffect, useRef, useState} from "react";
import './FileItem.scss';
import GlobalService from "../../Services/Global/GlobalService";
import {FILE_TYPE_ICONS} from "../../Constants/GlobalConstants";
import {faLock, faLockOpen} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const FileItem = ({file}) => {
    const menuRef = useRef(null);
    const [displayProperties, setDisplayProperties] = useState(false);
    const [listening, setListening] = useState(false);
    const toggle = () => setDisplayProperties(!displayProperties);
    const globalService = new GlobalService();

    useEffect(globalService.listenForOutsideClicks(
        listening,
        setListening,
        menuRef,
        setDisplayProperties,
    ));

    const extension = FILE_TYPE_ICONS[file.filetype] ? FILE_TYPE_ICONS[file.filetype] : FILE_TYPE_ICONS.none;
    return (
        <div ref={menuRef} className='d-flex file-item col-md-3 col-sm-12 col-lg-3 col-xl-2'>
            <div className='file-action' onClick={toggle}>
                <FontAwesomeIcon icon={file.action === 'encrypt' ? faLock : faLockOpen}/>
            </div>
            <div className={`file-icon ${extension}`} onClick={toggle}></div>
            <div className='file-details' onClick={toggle}>
                <div className='file-name' title={file.name}>
                    {file.name}
                </div>
                <div className='file-date' onClick={toggle}>
                    {globalService.generateFileDateFromTimeStamp(parseInt(file.timestamp))}
                </div>
            </div>

            <div className="dropdown">
                <div className={`dropdown-menu file-properties ${displayProperties ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
                    <div>{file.action === 'encrypt' ? 'For' : 'From'}: {file.email}</div>
                    <div>Date: {globalService.generateFileDateFromTimeStamp(parseInt(file.timestamp))}</div>
                    <div>Action: {file.action}</div>
                    <div>Size: {Math.round(file.size / 1000)} kb</div>
                </div>
            </div>
        </div>
    );
};