import React from 'react';
import  './BottomMainTabs.scss';
import {Col, Container, Row} from "reactstrap";
import addContactIcon from "../../../assets/contact2-01-01.svg";
import encryptFileIcon from "../../../assets/encrypt-01.svg";
import decryptFileIcon from "../../../assets/decrypt-01.svg";
import addIcon from "../../../assets/add-01.svg";
import {withRouter} from "react-router";

class BottomMainTabs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTab: window.location.pathname
        }
    }

    setTabState = (tab, callback) => {
        this.setState({
            currentTab: tab
        }, callback);
    };

    render() {
        const {onAddContact, onDecryptFile, onEncryptFile} = this.props;
        const {currentTab} = this.state;

        return (
            <Container fluid className='bottom-bar-custom d-md-none d-block'>
                <div style={{borderRight: '1px solid rgba(0, 0, 0, 0.35)'}} className={`d-inline-block tab ${currentTab.includes('contacts') ? 'selected' : ''}`}
                     onClick={() => {
                         this.setTabState('contacts', () => {
                             this.props.history.push('/contacts');
                         })
                     }}>
                    CONTACTS
                </div>
                <div className={`d-inline-block add-button`}>
                    <div className='add-contact-container'>
                        <div className="sub-button shadow" onClick={() => {
                            onAddContact()
                        }}>
                            <span className="material-icons"><img src={addContactIcon}/></span>
                        </div>
                        <div className="sub-button shadow" onClick={() => onDecryptFile()}>
                            <span className="material-icons"><img src={decryptFileIcon}/></span>
                        </div>
                        <div className="sub-button shadow" onClick={() => onEncryptFile()}>
                            <span className="material-icons"><img src={encryptFileIcon}/></span>
                        </div>
                        <div className='add-contact'>
                            <div className='plus-sign'>
                                <img src={addIcon}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`d-inline-block tab ${currentTab.includes('files') ? 'selected' : ''}`} onClick={() => {
                    this.setTabState('files', () => {
                        this.props.history.push('/files');
                    })
                }}>
                   FILE HISTORY
                </div>
            </Container>
        )
    }
}

export default withRouter(BottomMainTabs);
