import onClickOutside from "react-onclickoutside";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import React from 'react';
import {CONTACT_ACTION_TYPES, CONTACT_LIST_TYPE} from "../../../Constants/GlobalConstants";

class ContactActions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    setOpen = (toggle) => {
        this.setState({
            isOpen: toggle
        });
    }

    handleClickOutside = () => this.setOpen(false);

    render() {
        const {isOpen} = this.state;
        const {type, action} = this.props;
        return (
            <div style={{position: 'relative'}}>
                <div className="nav-link dropdown-toggle ps-4 pe-4 pt-2 pb-2" id="navbarDropdown" role="button"
                     data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => this.setOpen(!isOpen)}>
                    <FontAwesomeIcon icon={faEllipsisV} color={'#262525'}/>
                </div>
                <div className={`dropdown-menu ${isOpen ? 'show' : ''}`} aria-labelledby="navbarDropdown">
                    {
                        type === CONTACT_LIST_TYPE.PENDING ?
                            (
                                <>
                                    <div className="dropdown-item text-left" onClick={() => {
                                        action({type: CONTACT_ACTION_TYPES.ACCEPT})
                                    }}>Accept</div>
                                    <hr className="dropdown-divider"/>
                                    <div className="dropdown-item text-left" onClick={() => {
                                        action({type: CONTACT_ACTION_TYPES.DECLINE});
                                    }}>Decline</div>
                                </>
                            ) : null
                    }
                    {
                        type === CONTACT_LIST_TYPE.REQUEST ?
                            (
                                <>
                                    <div className="dropdown-item text-left" onClick={() => {
                                        action({type: CONTACT_ACTION_TYPES.REQUEST_DECLINE});
                                    }}>Decline</div>
                                </>
                            ) : null
                    }
                    {type === CONTACT_LIST_TYPE.EXISTING ?
                        (
                            <>
                                <div className="dropdown-item text-left" onClick={() => {
                                    action({type: CONTACT_ACTION_TYPES.ENCRYPT});
                                }}>Encrypt</div>
                                <hr className="dropdown-divider"/>
                                <div className="dropdown-item text-left" style={{backgroundColor: ""}} onClick={() => {
                                    action({type: CONTACT_ACTION_TYPES.DELETE});
                                }}>Delete</div>
                            </>
                        ) : null
                    }
                </div>
            </div>
        );
    }
};

export default onClickOutside(ContactActions);