import React from "react";
import { Progress } from "reactstrap";
import "./ProgressBarCryption.scss";

const ProgressBarCryption = ({ isLoading, label }) => {
  return (
    <>
      {isLoading && (
        <Progress
          color="warning"
          className="mx-auto mt-3 w-full black-label-progress-bar"
          variant="custom"
          style={{ fontSize: "17px", height: "30px" }}
          value={"100"}
          animated
        >
          {label}
        </Progress>
      )}
    </>
  );
};

export default ProgressBarCryption;
