import { Button, Spinner } from "reactstrap";

/**
 * KripteraButton will be used when executing a request with it. It will display a loading spinner and label until
 * request is completed.
 * @param label string. Custom label for button.
 * @param disabled boolean. Will be disabled when:
 * 1. On register screen terms are not accepted.
 * 2. On request execution.
 * @param isLoading boolean. Parent state. Changes to true when request is executed.
 * @param onClick function. Async function provided by parent component.
 * @returns Button functional component. Styling is provided by reactstrap library.
 */

const KripteraButton = ({
  label,
  disabled,
  isLoading,
  onClick,
  customButtonStyle,
}) => {
  const buttonClassName = "primary-button-color";
  const buttonColor = "primary";
  const buttonStyle = { maxHeight: "38px" };
  const loadingLabel = "Loading...";

  if (isLoading) {
    return (
      <Button
        disabled
        className={buttonClassName}
        style={customButtonStyle ? customButtonStyle : buttonStyle}
        color={buttonColor}
      >
        <Spinner size="sm" color="warning">
          {loadingLabel}
        </Spinner>
        <span> {loadingLabel}</span>
      </Button>
    );
  }

  return (
    <Button
      disabled={disabled} // Will be disabled only on Register screen when terms of use are not accepted.
      className={buttonClassName}
      style={customButtonStyle ? customButtonStyle : buttonStyle}
      onClick={onClick}
      color={buttonColor}
    >
      {label}
    </Button>
  );
};

export default KripteraButton;
