import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

/**
 * This modal will be visible when a paid account is trying to accept or invite a new contact when he has filled his contacts limit.
 */
const ContactsLimitModal = ({ isOpen, confirmAction }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        confirmAction(false);
      }}
      centered
    >
      <ModalHeader>Reached contacts limit</ModalHeader>
      <ModalBody>
        You have reached your contacts limit on your plan. If you want to add or
        accept a new contact, you need to delete a current one.
      </ModalBody>
      <ModalFooter>
        <Button
          className="primary-button-color"
          style={{ maxHeight: "38px" }}
          onClick={() => {
            confirmAction(false);
          }}
          color="primary"
        >
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ContactsLimitModal;
