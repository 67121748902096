import FileSaver from "file-saver";
import {KRIPTERA_EXTENSION} from "../../Constants/GlobalConstants";

export default class FileService {

    download = (content, filename, isMyFile) => {
        const myFile = 'my-encrypted-';
        let blob = new Blob([content], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob, `${isMyFile ? myFile + filename : filename}${KRIPTERA_EXTENSION}` );
    }

    downloadDecrypted = (content, filename) => {
        let blob = new Blob([content], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob, `${filename}` );
    }
}
