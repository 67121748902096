import React from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import KripteraButton from "../../../Partials/KripteraButton/KripteraButton";
import Http from "../../../Services/Http/Http";
import { isEmailValid } from "../../../helpers/helpers";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
export default class PasswordResetModal extends React.Component {
  http = new Http();
  captchaRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      email: "",
      loading: false,
      success: false,
      recapchaChecked: false,
      showRecapcha: true,
    };
  }

  setSuccess = () => {
    this.setState({
      success: true,
      loading: false,
      errorMessage: "",
    });
  };

  setLoading = (loading, func) => {
    this.setState({ loading: loading }, func);
  };

  setEmail = (email) => {
    this.setState({
      email: email,
    });
  };

  setErrorMessage = (message) => {
    this.setState({ errorMessage: message });
  };

  setRecaptcha = (toggle) => {
    this.setState({
      recapchaChecked: toggle,
    });
  };

  setShowRecaptcha = (toggle) => {
    this.setState({
      showRecapcha: toggle,
    });
  };

  resetPassword = (email) => {
    this.setShowRecaptcha(false);
    this.setLoading(true);
    const token = this.captchaRef.current.getValue();
    this.captchaRef.current.reset();

    axios
      .post("https://recaptcha.zibid.io/verify-token", { token })
      .then((response) => {
        if (response.status === 200) {
          this.http
            .resetPassword(email)
            .then((_) => {
              this.setSuccess();
            })
            .catch((e) => {
              this.setLoading(false, () => {
                if (parseInt(e.response.status) === 404) {
                  this.setErrorMessage(
                    "User with provided email does not exist"
                  );
                }
                if (parseInt(e.response.status) === 403) {
                  this.setErrorMessage("Please input a valid email");
                }
              });
            });
        } else {
          this.setLoading(false);
          this.setShowRecaptcha(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { isOpen, toggle } = this.props;
    const {
      errorMessage,
      email,
      loading,
      success,
      recapchaChecked,
      showRecapcha,
    } = this.state;
    const isPasswordResetButtonDisabled =
      recapchaChecked && this.state.email && isEmailValid(this.state.email)
        ? false
        : true;

    return (
      <>
        <Modal
          isOpen={isOpen}
          toggle={() => {
            toggle(false);
          }}
          centered
        >
          <ModalHeader>Password reset</ModalHeader>
          <ModalBody>
            {success ? (
              <>
                An email has been sent, please follow the instructions to reset
                your password.
              </>
            ) : (
              <>
                <Row className={"mb-3"}>
                  <Col>
                    To reset your password, please input your email which you
                    have used for your registration.
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className="mb-3" controlid="formBasicEmail">
                      <Input
                        type="email"
                        placeholder="Enter email"
                        onChange={(evt) => this.setEmail(evt.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Col className="text-danger">{errorMessage}</Col>
                  </Col>
                </Row>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            {showRecapcha ? (
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={this.captchaRef}
                hl="en"
                onChange={() => this.setRecaptcha(true)}
              />
            ) : null}
            <Button
              color="secondary"
              onClick={() => {
                this.setState({
                  errorMessage: "",
                  email: "",
                  loading: false,
                  success: false,
                  recapchaChecked: false,
                  showRecapcha: true,
                });
                toggle(false);
              }}
              disabled={loading}
            >
              Close
            </Button>
            {!success ? (
              <KripteraButton
                label={"Submit"}
                disabled={isPasswordResetButtonDisabled}
                isLoading={loading}
                onClick={() => {
                  this.resetPassword(email);
                }}
              />
            ) : null}
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
