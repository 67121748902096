import React, {useState} from "react";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const RemoveContact = (props) => {
    const [confirmDelete, setConfirmDelete] = useState(false);

    return (
        <div className='d-flex justify-content-center align-content-center' onClick={() => {
            if (confirmDelete) {
                props.remove();
                setConfirmDelete(false);
                return;
            }
            setConfirmDelete(true);
            setTimeout(() => setConfirmDelete(false), 3000);
        }}>
            <div style={{'cursor':'pointer'}}>
                {confirmDelete ? <div style={{'color':'#ff9c6b'}}>Click to confirm!</div> : <FontAwesomeIcon color={'#dc3545'} icon={faTrash}/>}
            </div>
        </div>
    );
};
