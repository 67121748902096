import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom/';
import {getAuth} from "../global";

const PrivateRoute = ({ component: Component, ...rest }) => {
    
    return (
        <Route {...rest} render={(props) =>
                getAuth().token.length > 0 ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    )
};

export default PrivateRoute;
