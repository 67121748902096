const CLIENT_ID = '1059415998404-9spdr5imh5383j8tvcnka4j1tens4df6.apps.googleusercontent.com';
const API_KEY = 'AIzaSyAPmrmAZ8g9atJ8c6lpqrGmWrj3OK1N0rA';
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];
const SCOPES = 'https://www.googleapis.com/auth/drive.file';

export default class GoogleDriveService {

    listenForSignIn = (callback, withRetry) => {
        setTimeout(() => {
            if (window.gapi.auth2 && window.gapi.auth2.getAuthInstance()) {
                window.gapi.auth2.getAuthInstance().isSignedIn.listen(callback);
            }
        }, 1000);
    };

    renderSignIn = () => {
        window.gapi.signin2.render('my-signin2', {
            'scope': 'profile email',
            'width': 240,
            'height': 50,
            'longtitle': true,
            'theme': 'dark'
        });
    };
}
