import React, {useEffect, useState} from 'react';
import {ContactsDropdown} from "../ContactListDropdown/ContactsDropdown";
import {Button} from "reactstrap";
import './ContactsSubscribe.scss';
import ContactsSubscriptionService from "../../../Services/Subscription/ContactsSubscriptionService";
import { toast } from 'react-toastify';
import { ERROR_MESSAGES } from '../../../Constants/GlobalConstants';
const subscriptionService = new ContactsSubscriptionService();

export const ContactsSubscribe = ({onSubsUpdate, subscribers}) => {
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [loading, setLoading] = useState(false);

    const addContacts = () => {
        if (selectedContacts && selectedContacts.length > 0) {
            const contactsToSubscribe = selectedContacts.map((contact) => contact.value);
            setLoading(true);
            subscriptionService.addContactsSubscription(contactsToSubscribe).then(() => {
                setLoading(false);
                setSelectedContacts([]);
                onSubsUpdate();
            }).catch((e) => {
                toast.error(e.response.data.message ? e.response.data.message : ERROR_MESSAGES.ERROR_ADD_SUBSCRIBERS);
                setLoading(false);
            });
        }
    };

    return (
        <div className='dropdown-container d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-center'>
            <ContactsDropdown selectedContacts={selectedContacts} existingSubscribers={subscribers}
                              className='contacts-dropdown me-3 mb-3 mb-md-0 mb-sm-0' onSelect={(selection) => {
                setSelectedContacts(selection);
            }}/>
            <Button disabled={loading} onClick={() => {
                addContacts()
            }}>
                Add
            </Button>
        </div>
    );
};