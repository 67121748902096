import { useState } from "react";
import { useHistory } from "react-router";
import { logout } from "../../../Store/Actions/GeneralActions";
import { store } from "../../../Store/store";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import { useIdleTimer } from "react-idle-timer";
import {setToken} from "../../../global";

const SessionTimeoutModel = () => {
  const LOGOUT_TIMEOUT = process.env.APP_TIMEOUT_LOGOUT;
  const LOGOUT_PROMPT = process.env.APP_TIMEOUT_LOGOUT;

  const [showPrompt, setShowPrompt] = useState(false);
  const history = useHistory();

  const onPrompt = () => {
    setShowPrompt(true);
  };

  const onIdle = () => {
    setShowPrompt(false);
    const userInfo = store.getState().user.info;
    if (
      userInfo !== null &&
      userInfo.token !== undefined &&
      userInfo.token !== null &&
      userInfo.token.length > 0
    ) {
      setTimeout(() => {
        logoutUser();
      }, 350);
    }
  };

  const onActive = (event) => {};

  const idleTimer = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: LOGOUT_TIMEOUT,
    promptTimeout: LOGOUT_PROMPT,
  });

  const logoutUser = () => {
    store.dispatch(logout());
    history.push("/");
  };

  return (
    <ConfirmationModal
      isOpen={showPrompt}
      confirmAction={(userResponse) => {
        if (userResponse) {
          setShowPrompt(false);
          idleTimer.reset();
        } else {
          setShowPrompt(false);
          logoutUser();
        }
      }}
      confirmButton={"Yes"}
      declineButton={"No"}
      message={"Your session is about to expire do you want to extend it ?"}
    />
  );
};

export default SessionTimeoutModel;
