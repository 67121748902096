import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
} from "reactstrap";

const FileExceedSizeModal = (props) => {
  const {
    isOpen,
    confirmAction,
    confirmButton,
    message,
    title,
    subscriptionType,
  } = props;
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        confirmAction(false);
      }}
      centered
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        {message}
        {subscriptionType === "free" ? (
          <a href={`${process.env.REACT_APP_PLANS_LINK}`} target="_blank">
            here
          </a>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button
          className={"primary-button-color"}
          style={{ maxHeight: "38px" }}
          onClick={() => confirmAction(false)}
          color={"primary"}
        >
          {confirmButton}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FileExceedSizeModal;
