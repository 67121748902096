export const CONTACT_LIST_TYPE = { REQUEST: 'request', PENDING: 'pending', EXISTING: 'existing'};
export const CONTACT_ACTION_TYPES = {DELETE: 'delete', ACCEPT: 'accept', DECLINE: 'decline', ENCRYPT: 'encrypt', CLICK: 'click', REQUEST_DECLINE: 'request_decline'};
export const USER_KEYS_ACTION_TYPES = {AVAILABLE: 'available', GENERATING: 'generating', GENERATED: 'generated'};
export const TEMP_KEY_MESSAGES  = {"generating":"New key pair is being generated, please wait.", "generated": "Key pair has been generated."}
export const KRIPTERA_EXTENSION = '.krpt';
export const FILE_OPERATIONS = { DECRYPT: 'decrypt', ENCRYPT: 'encrypt' };
export const FILE_TYPE_NONE = 'none';
export const SUBSCRIPTION_TYPE = {free: "free"};
export const WARNING_MESSAGES = {
    ADD_CONTACT_ME: "User can't send contact request to themself",
    SIZE_LIMIT: ""
};
export const ERROR_MESSAGES = {
    ERROR_DECRYPT: "There was a a problem with decrypting the file. Please try again.",
    ERROR_ENCRYPT: "There was a a problem with encrypting the file. Please try again.",
    ERROR_ENCRYPT_MYSELF: "There was a a problem with encrypting the file for myself. Please try again.",
    REPLACE_CONTACT: "There was a problem with replacing the contact. Please try again.",
    ADD_CONTACT: "You have reached your contacts limit!",
    ACCEPT_CONTACT: "You have reached your contacts limit!",
    DECLINE_CONTACT: "There was a problem with your request: Decline contanct, please try again later",
    ERROR_ADD_SUBSCRIBERS: "There was a problem adding users to your subscription plan. Please try again.",
    ERROR_SUBSCRIPTION_INFO: "There was a problem with getting information about subscription. Please try again."
};
export const CONTACTS_TO_LIST = {
    contacts: "contacts",
    sent: "requested",
    pending: "pending"
};

export const CRYPTING_IN_PROCESS = "You are not allowed to execute another encryption or decryption process";
export const displayReplaceContactMessage = (email, property) => `You have reached your contacts limit on your free plan. ${email} in ${property} list will be replaced. Do you wish to proceed?`

export const exceededFileSizeLimitMessage = (subscription) => {
    const message = 'You have reached your file size limit';

    if (subscription === "free")
        return (
            message +
            ` on your ${subscription} plan. Please consider upgrading your subscription. More information about our plans you can find `
        );

    return message;
}
export const FILE_TYPE_ICONS = {
    pdf: 'pdf',
    docx: 'doc',
    exe: 'exe',
    doc: 'doc',
    xls: 'xls',
    xlsx: 'xls',
    mp3: 'mp3',
    ogg: 'mp3',
    wav: 'wav',
    mp4: 'avi',
    mov: 'avi',
    zip: 'zip',
    '7z': 'z7',
    rar: 'rar',
    jpg: 'jpg',
    jpeg: 'jpg',
    png: 'png',
    none: 'file'
};