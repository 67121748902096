import React from 'react';
import {Subscription} from "rxjs";
import {Row} from "reactstrap";
import './ContactsList.scss';
import ExpandableContactList from "../Global/ExpandableContactList/ExpandableContactList";
import {actionsPubSub, contactsService} from "../MainLayout/MainLayout";
import {RESET_CONTACT_LIST} from "../../Services/ActionsPubSub/ActionsPubSub";
import {
    CONTACT_ACTION_TYPES,
    CONTACT_LIST_TYPE,
    FILE_OPERATIONS,
    ERROR_MESSAGES,
    CRYPTING_IN_PROCESS,
    exceededFileSizeLimitMessage,
    KRIPTERA_EXTENSION
} from "../../Constants/GlobalConstants";
import EncryptionService from "../../Services/EncryptionService/EncryptionService";
import {store} from "../../Store/store";
import HttpRefreshToken from "../../Services/Http/HttpRefreshToken";
import {toast} from "react-toastify";
import FileService from "../../Services/FileService/FileService";
import FileHistoryService from "../../Services/FileHistoryService/FileHistoryService";
import {excludeFilesExceedingLimits, isFileSizeExceedLimit} from "../../helpers/helpers";
import FileExceedSizeModal from "../Modals/FileExceedSizeModal/FileExceedSizeModal";
import JSZip from "jszip";
import FileSaver from "file-saver";

export default class ContactList extends React.Component {
    contactsServiceSubscription = new Subscription();
    actionPubSubSubscription = new Subscription();
    uploadDecrypt = null;
    uploadEncrypt = null;
    fileService = new FileService();
    fileHistoryService = new FileHistoryService();
    subscriptionType = null;

    constructor(props) {
        super(props);
        this.subscriptionType = store.getState().user.info.subscription.type;
        this.state = {
            pending: [],
            existing: [],
            sent: [],
            contactActionEmail: '',
            displayFileExceedSize: false,
            filesExcluded: false
        }
    }

    addListeners = () => {
        this.actionPubSubSubscription = actionsPubSub.getAction().next((action) => {
            if (action.type === RESET_CONTACT_LIST) {
                contactsService.refreshContactList();
            }
        })
    };

    componentDidMount() {
        this.contactsServiceSubscription = contactsService.contactsSubject.subscribe((contacts) => {
            this.setContacts(contacts);
        });
        contactsService.getContactList(30000);
    }

    componentWillUnmount() {
        this.contactsServiceSubscription.unsubscribe();
        contactsService.clear();
        this.actionPubSubSubscription.unsubscribe();
    }

    toggleFileExceedSize = (toggle) => {
        this.setState({
            displayFileExceedSize: toggle
        })
    };

    setContacts = (contacts) => {
        this.setState({
            pending: contacts.pending,
            sent: contacts.sent,
            existing: contacts.existing
        });
    };
    
    onChangeFileDecrypt = async (event) => {
        const zip = new JSZip();
        const { isLoading, toggleIsLoading, labelCrypting } = this.props;

        if (isLoading) {
            toast.error(CRYPTING_IN_PROCESS);
            return;
        }

        toggleIsLoading(true);
        let files = [];
        const service = new EncryptionService();

        for (let i = 0; i < event.target.files.length; i++) {
            files.push(event.target.files[i]);
        }
        files = excludeFilesExceedingLimits(files, this.subscriptionType, FILE_OPERATIONS.ENCRYPT)

        if (files.length > 0) {
            for (const file of files) {
                try {
                    this.labelCrypting.current = `Decrypting ${file.name}`;
                    const decryptedContent = await service.decrypt(file);
                    await zip.file(`${decryptedContent.name}`, decryptedContent);
                    this.fileHistoryService.pushFile(store.getState().user.info.email, files[0], FILE_OPERATIONS.DECRYPT);
                } catch (error) {
                    console.warn(error);
                    toast.error(ERROR_MESSAGES.ERROR_DECRYPT);
                }
            }
            zip.generateAsync({type: 'blob'}).then((content) => {
                FileSaver.saveAs(content, 'decrypted.zip');
            });
        } else {
            try {
                this.labelCrypting.current = `Decrypting ${files[0].name}`;
                const decryptedContent = await service.decrypt(files[0]);
                this.fileService.downloadDecrypted(decryptedContent, decryptedContent.name);
                this.fileHistoryService.pushFile(store.getState().user.info.email, files[0], FILE_OPERATIONS.DECRYPT);
            } catch (error) {
                console.warn(error);
                toast.error(ERROR_MESSAGES.ERROR_DECRYPT);
            }
        }

        this.toggleIsLoading(false);
        event.target.value = null;
    }

    onChangeFileEncrypt = async (event) => {
        const zip = new JSZip();
        const { isLoading, toggleIsLoading, labelCrypting } = this.props;
        this.setState({...this.state, filesExcluded: false});
        if (isLoading) {
            toast.error(CRYPTING_IN_PROCESS);
            return;
        }

        toggleIsLoading(true);
        let files = [];
        const service = new EncryptionService();
        const email = this.state.contactActionEmail;

        for (let i = 0; i < event.target.files.length; i++) {
            files.push(event.target.files[i]);
        }
        
        files = excludeFilesExceedingLimits(files, this.subscriptionType, FILE_OPERATIONS.ENCRYPT, () => {
            toast.error('There were files that had exceeded the encryption limit. They will be excluded.')
        })
        
        if (files.length < 1) {
            toggleIsLoading(false);
            return;
        }
        
        const publicKey = await new HttpRefreshToken().getContactPublicKey(email);
        
        for ( const file of files) {
            labelCrypting.current = `Encrypting ${file.name}`;
            try {
                const encryptedContent = await service.encrypt(publicKey, file);
                let blob = new Blob([encryptedContent], {type: "text/plain;charset=utf-8"});
                await zip.file(`${file.name}${KRIPTERA_EXTENSION}`, blob);
                this.fileHistoryService.pushFile(store.getState().user.info.email, file, FILE_OPERATIONS.ENCRYPT);
                if (event.target.files.length !== files.length) {
                    toast.warn('Some files were exceeding the maximum size limit and have been excluded during encryption', { autoClose: false})
                    this.setState({...this.state, filesExcluded: true})
                }
            } catch (error) {
                console.warn(error);
                toast.error(ERROR_MESSAGES.ERROR_ENCRYPT);
            }
        }
        zip.generateAsync({type: 'blob'}).then((content) => {
            FileSaver.saveAs(content, 'encrypted.zip');
        });

        toggleIsLoading(false);
    }

    onEncrypt = (email) => {
        this.setState({contactActionEmail: email}, () => {
            this.uploadEncrypt.click();
        })
    }

    render() {
        const { replaceContact, contactsLimitModal } = this.props;
        const {pending, sent, existing, displayFileExceedSize} = this.state;

        return (
            <>
                <Row style={{backgroundColor: 'white'}}>
                    <ExpandableContactList type={CONTACT_LIST_TYPE.REQUEST} replaceContact={replaceContact} contactsLimitModal={contactsLimitModal}
                        existingContacts={{ existing }} contacts={sent} text='Requested' />
                    <ExpandableContactList type={CONTACT_LIST_TYPE.PENDING} replaceContact={replaceContact} contactsLimitModal={contactsLimitModal}
                        existingContacts={{ existing }} contacts={pending} text='Pending' />
                    <ExpandableContactList type={CONTACT_LIST_TYPE.EXISTING} replaceContact={replaceContact} contactsLimitModal={contactsLimitModal}
                        allCoexistingContactsntacts={{ existing }} contacts={existing} text='Contacts' action={(action) => {
                            if (action.type === CONTACT_ACTION_TYPES.ENCRYPT) {
                                this.onEncrypt(action.payload.email);
                            }
                    }}/>
                    {/*Hidden file encrypt input*/}
                    <input id="myInput"
                        type="file"
                        multiple
                        ref={(ref) => this.uploadEncrypt = ref}
                        style={{display: 'none'}}
                        onClick={(event) => {
                            event.target.value = null
                        }}
                        onChange={this.onChangeFileEncrypt.bind(this)}
                    />
                    {/*Hidden file decrypt input*/}
                    <input id="myInput"
                        type="file"
                        multiple
                        ref={(ref) => this.uploadDecrypt = ref}
                        style={{display: 'none'}}
                        onClick={(event) => {
                            event.target.value = null
                        }}
                        onChange={this.onChangeFileDecrypt.bind(this)}
                    />
                </Row>
                <FileExceedSizeModal
                    isOpen={displayFileExceedSize}
                    title={"File exceeded"}
                    message={exceededFileSizeLimitMessage(this.subscriptionType)}
                    confirmButton={'OK'}
                    confirmAction={(isConfirmed) => this.toggleFileExceedSize(isConfirmed)}
                    subscriptionType={this.subscriptionType}
                />
            </>
        );
    }
}
