import React from 'react';
import {Row} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import {CONTACT_LIST_TYPE} from "../../../Constants/GlobalConstants";
import './DelimiterCounter.scss';

export default class DelimiterCounter extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const {number, text, onClick, isExpanded, type} = this.props;

        return (
            <Row className='mb-3 d-flex flex-row justify-content-center align-items-center'>
                <div className='contact-list-label'>
                    {text}
                </div>
                <div className='contact-list-divider d-flex flex-row justify-content-center align-items-center p-0'>
                    <div className='divider-contacts'></div>
                </div>
                <div className='text-end'>
                    {number}
                    {type !== CONTACT_LIST_TYPE.EXISTING ?
                        (
                            <div className='expand-contacts' onClick={() => onClick()}>
                                <FontAwesomeIcon icon={isExpanded ? faMinus : faPlus}/>
                            </div>
                        ) :
                        (
                            <div className='expand-contacts'>

                            </div>
                        )
                    }
                </div>


            </Row>
        );
    }
}