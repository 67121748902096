import HttpRefreshToken from "../Services/Http/HttpRefreshToken";
import {store} from "../Store/store";
import {ERROR_MESSAGES, FILE_OPERATIONS} from "../Constants/GlobalConstants";
import {toast} from "react-toastify";

export const isEmailValid = (email) => {
  let re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const extractContactEmail = (contactsObject) => {
  if (contactsObject) {
    const { contacts, sent, pending } = contactsObject;
    const contactsArray = JSON.parse(contacts);
    const sentArray = JSON.parse(sent);
    const pendingArray = JSON.parse(pending);
    let contactEmail = "";

    if (contactsArray && contactsArray.length) {
      contactEmail = contactsArray[0].email;
    }

    if (sentArray && sentArray.length) {
      contactEmail = sentArray[0].email;
    }

    if (pendingArray && pendingArray.length) {
      contactEmail = pendingArray[0].email;
    }

    return contactEmail;
  }
};

export const isFileSizeExceedLimit = (
  file,
  subscriptionType,
  fileOperation
) => {
  const limitBytesFreeEncryption = 1_048_576;
  const limitBytesPaidEncryption = 73_400_320;
  const limitBytesFreeDecryption = 2_097_152;
  const limitBytesPaidDecryption = 146_800_640;
  let isExceed = true;

  if (fileOperation === "encrypt") {
    if (subscriptionType === "free" && file.size > limitBytesFreeEncryption)
      return isExceed;
    else if (
      subscriptionType !== "free" &&
      file.size > limitBytesPaidEncryption
    )
      return isExceed;
  } else if (fileOperation === "decrypt") {
    if (subscriptionType === "free" && file.size > limitBytesFreeDecryption)
      return isExceed;
    else if (
      subscriptionType !== "free" &&
      file.size > limitBytesPaidDecryption
    )
      return isExceed;
  }

  isExceed = false;

  return isExceed;
};

export const excludeFilesExceedingLimits = (files, subscriptionType, fileOperation, onFileExceeded) => {
  const initialLength = files.length;
  
  const _files = files.filter((file) => {
    return !isFileSizeExceedLimit(file, subscriptionType, fileOperation)
  });
  
  const afterFilterLength = _files.length;
  
  if (initialLength > afterFilterLength && onFileExceeded) {
    onFileExceeded()
  }
  
  return _files;
};