import React from 'react';
import { Col, Container, Row} from "reactstrap";
import './RegisterComponent.scss';
import NavigationTabLogin from "../Global/NavigationTabLogin/NavigationTabLogin";
import {FormGroup, Input} from 'reactstrap';
import Registration from "./Model/RegistrationModel";
import ValidateUserInput from "./Controller/ValidateUserInput";
import ValidationModel from "./Model/ValidationModel";
import Http from "../../Services/Http/Http";
import {toast} from "react-toastify";
import {withRouter} from "react-router";
import {store} from "../../Store/store";
import KripteraButton from "../../Partials/KripteraButton/KripteraButton";
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
class Register extends React.Component {
    http = new Http();
    captchaRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            registrationModel: new Registration(),
            validationModel: new ValidationModel(),
            termsChecked: false,
            recapchaChecked: false,
            isLoading: false
        };
    }

    toggleTerms = (toggle) => {
        this.setState({
            termsChecked: toggle
        })
    };

    toggleRecaptcha = (toggle) => {
        this.setState({
            recapchaChecked: toggle
        })
    };

    updateFirstname = (firstName) => {
        this.updateRegisterAndValidation('firstName', firstName);
    };

    updateLastname = (lastName) => {
        this.updateRegisterAndValidation('lastName', lastName);
    };

    updateEmail = (email) => {
        this.updateRegisterAndValidation('email', email);
    };

    updateConfirmEmail = (confirmEmail) => {
        this.updateRegisterAndValidation('confirmEmail', confirmEmail);
    };

    updatePassword = (password) => {
        this.updateRegisterAndValidation('password', password);
    };

    updateConfirmPassword = (confirmPassword) => {
        this.updateRegisterAndValidation('confirmPassword', confirmPassword);
    };

    updateRegisterAndValidation = (inputType, value) => {
        const {registrationModel, validationModel} = this.state;
        const userInputValidator = new ValidateUserInput();

        switch (inputType) {
            case 'firstName':
                registrationModel.firstname = value;
                validationModel.isFirstNameEmpty = false;
                break;
            case 'lastName':
                registrationModel.lastname = value;
                validationModel.isLastNameEmpty = false;
                break;
            case 'email':
                registrationModel.email = value;
                validationModel.isEmailEmpty = false;
                validationModel.isEmailInvalid = false;
                validationModel.isEmailMismatch = false;
                break;
            case 'confirmEmail':
                registrationModel.confirmEmail = value;
                validationModel.isEmailConfirmEmpty = false;
                validationModel.isEmailInvalid = false;
                validationModel.isEmailMismatch = false;
                break;
            case 'password':
                registrationModel.password = value;
                validationModel.isPasswordEmpty = false;
                validationModel.isPasswordMismatch = false;
                validationModel.isPasswordNotComplex = false;
                break;
            case 'confirmPassword':
                registrationModel.confirmPassword = value;
                validationModel.isPasswordConfirmEmpty = false;
                validationModel.isPasswordMismatch = false;
                validationModel.isPasswordNotComplex = false;
                break;
            default:
                break;
        }

        if (userInputValidator.registrationFormIsFilled(registrationModel)) {
            validationModel.errorMessage = '';
        }

        this.setState({
            registrationModel,
            validationModel
        });
    };

    toggleLoading = (toggle) => {
        this.setState({
            isLoading: toggle
        })
    };

    register = () => {
        this.toggleLoading(true);
        const { registrationModel } = this.state;
        const validation = new ValidateUserInput();
        const validationModel = validation.validate(registrationModel);
        const token = this.captchaRef.current.getValue();
        this.captchaRef.current.reset();
        axios.post(`https://${process.env.REACT_APP_RECAPTCHA_FQDN}/verify-token`, {token})
            .then((response) =>  {
                if (response.status === 200) {
                    if (!validationModel.isInvalid) {
                        this.http.register(registrationModel)
                        .then((_) => {
                            this.toggleLoading(false);
                            this.props.history.push('/login/r')
                        }).catch((e) => {
                            if (parseInt(e.response.status) === 409) {
                                toast.error('Email already exists!');
                            }
                        });
                    }
                } else {
                    this.setState({
                        validationModel,
                        isLoading: false
                    })
                    return;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        this.setState({
            validationModel,
            isLoading: false
        })
    };

    componentDidMount() {
        const userInfo = store.getState().user.info;

        if (userInfo !== null && userInfo.token !== undefined
            && userInfo.token !== null
            && userInfo.token.length > 0) {
            this.props.history.push('/contacts')
        }
    }

    isRegisterButtonDisabled() {
        const {firstname, lastname, email, confirmEmail, password, confirmPassword} = this.state.registrationModel;

        return firstname && lastname && email && confirmEmail && password && confirmPassword ? false : true;
    }

    render() {
        const {validationModel, termsChecked, recapchaChecked, isLoading} = this.state;

        return (
            <Container fluid className='fill fill-register main-container-register'>
                <Row className='fill fill-register'>
                    <Col lg={6} md={6} xs={0} sm={0} className={'d-none d-md-block d-lg-block register-wallpaper'}>
                    </Col>
                    <Col lg={6} md={6} xs={12} sm={12}
                         className='main-container-login-col py-md-5 py-sm-3 px-md-5 py-sm-2'>
                        <Row>
                            <Col sm={12} xs={12} lg={12} className='d-flex justify-content-center align-items-center'>
                                <NavigationTabLogin activeTab={'register'}/>
                            </Col>
                        </Row>
                        <Row className={'py-5'}>
                            <Col sm={12} xs={12} lg={12}>
                                <Row className={'mb-1 text-md-start text-sm-center'}>
                                    <Col className='text-60'>
                                        Welcome to Kriptera
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='text-20 text-md-start text-sm-center'>
                                        The Simplest and Fastest Way to Secure Your Files Online
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} xs={12} lg={6}>
                                <FormGroup className="mb-3" controlid="formBasicEmail">
                                    <Input type="text" placeholder="Enter first name"
                                           invalid={validationModel.isFirstNameEmpty}
                                           onChange={(evt) => this.updateFirstname(evt.target.value)}/>
                                </FormGroup>
                            </Col>
                            <Col sm={6} xs={12} lg={6}>
                                <FormGroup className="mb-3" controlid="formBasicEmail">
                                    <Input type="text" placeholder="Enter last name"
                                           invalid={validationModel.isLastNameEmpty}
                                           onChange={(evt) => this.updateLastname(evt.target.value)}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} xs={12} lg={6}>
                                <FormGroup className="mb-3" controlid="formBasicEmail">
                                    <Input type="email" placeholder="Enter valid email"
                                           invalid={validationModel.isEmailEmpty
                                           || validationModel.isEmailInvalid
                                           || validationModel.isEmailMismatch}
                                           onChange={(evt) => this.updateEmail(evt.target.value)}/>
                                </FormGroup>
                            </Col>
                            <Col sm={6} xs={12} lg={6}>
                                <FormGroup className="mb-3" controlid="formBasicEmail">
                                    <Input type="email" placeholder="Confirm email"
                                           invalid={validationModel.isEmailConfirmEmpty
                                           || validationModel.isEmailInvalid
                                           || validationModel.isEmailMismatch}
                                           onChange={(evt) => this.updateConfirmEmail(evt.target.value)}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} xs={12} lg={6}>
                                <FormGroup className="mb-3" controlid="formBasicEmail">
                                    <Input type="password" placeholder="Enter password"
                                           invalid={validationModel.isPasswordEmpty
                                           || validationModel.isPasswordMismatch
                                           || validationModel.isPasswordNotComplex}
                                           onChange={(evt) => this.updatePassword(evt.target.value)}/>
                                </FormGroup>
                            </Col>
                            <Col sm={6} xs={12} lg={6}>
                                <FormGroup className="mb-3" controlid="formBasicEmail">
                                    <Input type="password" placeholder="Confirm password"
                                           invalid={validationModel.isPasswordConfirmEmpty
                                           || validationModel.isPasswordMismatch
                                           || validationModel.isPasswordNotComplex}
                                           onChange={(evt) => this.updateConfirmPassword(evt.target.value)}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        {validationModel.isInvalid ?
                            <Row>
                                <Col className='text-danger'>
                                    {validationModel.errorMessage}
                                </Col>
                            </Row> : null
                        }
                        <Row lg={1} xl={2} className='mt-3'>
                            <Col className="mb-3">
                                <Row className='mb-4'>
                                    <Col>
                                        <input type="checkbox" className="btn-check" id="btncheck1" autoComplete="off" onChange={(evt) => this.toggleTerms(evt.target.checked)}/>
                                        <label className="btn btn-outline-primary" htmlFor="btncheck1">I agree with the
                                            terms and conditions</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='px-3'>
                                        <a href='https://zibid.io/terms-of-service/'>Terms of Service</a>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="mb-3">
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_SITE_KEY}
                                    ref={this.captchaRef}
                                    hl="en"
                                    onChange={() => this.toggleRecaptcha(true)}
                                />
                            </Col>
                            <Col className='register-button'>
                                <KripteraButton
                                    label="Register"
                                    disabled={!termsChecked || !recapchaChecked || this.isRegisterButtonDisabled()}
                                    isLoading={isLoading}
                                    onClick={this.register}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withRouter(Register);
