export default class ResponseTransformer {

    transformFileHistoryToArray = (resp) => {
        try {
            const userActions = [];
            const userKeys = Object.keys(resp);
            userKeys.forEach((userKey) => {
                const actions = this.actionsPerUser(resp[userKey]);
                userActions.push(...actions);
            })
            return  userActions;
        } catch (e) {
            console.error(e);
            return [];
        }
    };

    actionsPerUser = (userActions) => {
        let extractedActionFromOjb = userActions.map((action) => {
            const keys = Object.keys(action);
            return this.transformFileAction(action[keys[0]]);
        });
        return extractedActionFromOjb;
    };

    //Moves the contents of file: {} to the root node of the object
    transformFileAction = (action) => {
        action['timestamp'] = action.file.timestamp;
        action['action'] = action.file.action;
        action['filetype'] = action.file.filetype;
        action['name'] = action.file.name;
        action['note'] = action.file.note;
        action['size'] = action.file.size;

        delete action.file;

        return action;
    };

}