import React, { useState } from "react";
import GitInfo from "react-git-info/macro";
import packageJson from "../../../package.json";
import "./Footer.scss";
import arrowUp from "../../assets/up-arrow.png";
import arrowDown from "../../assets/down-arrow.png";

/**
 * Footer will be only visable on desktop version.
 */

const Footer = () => {
  const [showFooter, setShowFooter] = useState(false);

  const gitInfo = GitInfo();

  return (
    <>
      <button className="button" onClick={() => setShowFooter(!showFooter)}>
        {!showFooter ? (
          <img src={arrowUp} className="up-arrow" alt="up-arrow" />
        ) : (
          <img src={arrowDown} className="down-arrow" alt="down-arrow" />
        )}
      </button>
      {showFooter ? (
        <footer className="footer-container">
          <label
            className="text-center"
            style={{
              position: "fixed",
              bottom: "2px",
              width: "100%",
              backgroundColor: "#ff9c6b",
              marginBottom: "15px",
              fontFamily: "Roboto sans-serif",
            }}
          >
            <strong>Kriptera</strong>{" "}
            {gitInfo.tags[0] ? gitInfo.tags[0] : `v${packageJson.version}`} is a
            product developed by <strong>Zibid</strong> technologies.
          </label>
          <label
            className="text-center"
            style={{
              position: "fixed",
              fontSize: "12px",
              bottom: "0px",
              width: "100%",
              backgroundColor: "#ff9c6b",
              fontFamily: "Roboto sans-serif",
            }}
          >
            Copyright © 2019 Zibid Ltd.
          </label>
        </footer>
      ) : null}
    </>
  );
};

export default Footer;
