import HttpRefreshToken from "../Http/HttpRefreshToken";

export default class ContactsSubscriptionService {
    http = new HttpRefreshToken();

    getSubscriptionInformation = () => {
        return this.http.getSubscriptionInfo().then((resp) => {
            return JSON.parse(resp.data.active_subscription);
        });
    };

    getSubscribedContacts = () => {
        return this.http.getSubscribedContacts().then((resp) => {
            const contacts = resp.data;
            if (contacts.members) {
                return JSON.parse(contacts.members);
            } else {
                return [];
            }
        });
    };

    addContactsSubscription = (contacts) => {
        const requestBody = { members: {} };
        contacts.forEach((contact) => {
            requestBody.members[contact] = {};
            requestBody.members[contact].email = contact;
        });

        return this.http.addContactsSubscription(requestBody);
    };

    removeSubscriber = (email) => {
        const requestBody = { members: {}};
        requestBody.members[email] = {}
        requestBody.members[email].email = email;

        return this.http.removeSubscriber(requestBody);
    };
}