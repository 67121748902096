import React from 'react';
import './Contact.scss';
import {Col, Row, Spinner} from "reactstrap";
import ContactActions from "./ContactActions";
import {CONTACT_ACTION_TYPES} from "../../../Constants/GlobalConstants";

export default class Contact extends React.Component {

    render() {
        const {action, type} = this.props;
        const {firstname, lastname, email, letterFirstname, letterLastname, isLoading} = this.props.contact;

        return (
            <Col sm={12} xs={12} lg={6} md={6} className='ps-1 mb-4 position-relative'>
               <div className='contact-container'>
                   <Row>
                        <div className='letter'>
                            {`${letterFirstname}${letterLastname}`}
                        </div>
                       <Col md={6} sm={6} xs={6} className='d-flex flex-column justify-content-center'
                            onClick={() => action({type: CONTACT_ACTION_TYPES.CLICK})}>
                           <div >
                               <div className='contact-full-name text-start'>
                                   {`${firstname} ${lastname}`}
                               </div>
                               <div className='contact-email text-start'>
                                   {email}
                               </div>
                           </div>
                       </Col>
                       <Col md={3} sm={3} xs={3} className='d-flex flex-row justify-content-end'>
                           <div className='delete-contact'>
                               <ContactActions type={type} action={(contactAction) => action(contactAction)}/>
                           </div>
                       </Col>
                       {
                           isLoading && 
                           <div className='contact-loading-container'>
                               <Spinner size="md" color="warning" className='contact-loading'>
                                   Loading...
                               </Spinner>
                           </div>
                       }
                   </Row>
               </div>
            </Col>
        );
    }
}