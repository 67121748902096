import React from "react";
import userLogo from "../../../assets/user_icon.png";
import onClickOutside from "react-onclickoutside";
import { SettingsModal } from "../../Modals/SettingsModal/SettingsModal";
import { store } from "../../../Store/store";
import GlobalService from "../../../Services/Global/GlobalService";

class NavbarDropdown extends React.Component {
    globalService = new GlobalService();

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    setOpen = (toggle) => {
        this.setState({
            isOpen: toggle
        });
    }

    handleClickOutside = () => this.setOpen(false);

    render() {
        const {isOpen} = this.state;
        const firstName = this.globalService.upperCase(store.getState().user.info.firstName)
        const lastName = this.globalService.upperCase(store.getState().user.info.lastName)
        return (
            <div >
                <div className="nav-link dropdown-toggle me-2" id="navbarDropdown" role="button"
                     data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => this.setOpen(!isOpen)}>
                    <span className='text-decoration-none color-black me-3'>{`${firstName} ${lastName}`}</span>
                    <img className='img-circle' src={userLogo} width="35" height="35" alt=""/>
                </div>
                <div className={`dropdown-menu ${isOpen ? 'show' : ''}`} aria-labelledby="navbarDropdown">
                    <div className="dropdown-item text-center " onClick={() => {
                        this.props.onSettings();
                    }} >Settings</div>
                    <div className="dropdown-item text-center" onClick={() => {
                      this.props.onLogout();
                    }}><i className="fa fa-sign-out-alt"></i> Logout</div>
                </div>
            </div>
        );
    }
}

export default onClickOutside(NavbarDropdown);
