import React, {useEffect, useState} from "react";
import {FileItem} from "./FileItem";
import FileHistoryService from "../../Services/FileHistoryService/FileHistoryService";
import {Container, Row} from "reactstrap";
import './FileList.scss'

export const FilesList = () => {
    const fileHistoryService = new FileHistoryService();
    const [files, setFiles] = useState([]);

    useEffect(() => {
        fileHistoryService.getFiles().then((serverFiles) => {
            setFiles(serverFiles);
        });
    }, [files.length])


    return (
        <Container fluid={true} className={'file-container'}>
            <Row className='file-row'>
                {files.map((file) => {
                    return <FileItem key={`key-id-${file.timestamp}`} file={{...file}}/>
                })}
            </Row>
        </Container>
    )
};