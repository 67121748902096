import React from 'react';
import './NavigationTabLogin.scss';
import {withRouter} from "react-router";

class NavigationTabLogin extends React.Component {

    render() {
        const {activeTab, history} = this.props;
        return (
            <div className='navigation-tabs'>
                <div className={`py-3 px-4 ${activeTab === 'register' ? 'nav-tab-active' : ''}`} onClick={ () => {
                    history.push('/register');
                }}>
                    Register
                </div>
                <div className={`py-3 px-4 ${activeTab === 'login' ? 'nav-tab-active' : ''}`} onClick={ () => {
                    history.push('/login');
                }}>
                    Login
                </div>
            </div>
        );
    }
}

export default withRouter(NavigationTabLogin);