import DelimiterCounter from "../DelimiterCounter/DelimiterCounter";
import Contact from "../Contact/Contact";
import React from "react";
import {CONTACT_ACTION_TYPES, CONTACT_LIST_TYPE, ERROR_MESSAGES} from "../../../Constants/GlobalConstants";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import {contactsService} from "../../MainLayout/MainLayout";
import { store } from "../../../Store/store";
import { toast } from "react-toastify";

export default class ExpandableContactList extends React.Component {
    uploadDecrypt = null;
    uploadEncrypt = null;

    constructor(props) {
        super(props);
        this.state = {
            expandContacts: false,
            isConfirmationModalOpen: false,
            emailDelete: '',
            actionContactEmail: '',
            actionInProgress: false
        };
    }

    expandContacts = (expand) => {
        this.setState({
            expandContacts: expand
        });
    }

    onContactClick = (email) => {

    };

    onContactDecline = (email) => {
        this.setActionInProgress(true);
        contactsService.declineContactInvite(email).then(() => {
            this.setActionInProgress(false);
            contactsService.refreshContactList();
        }).catch(e => {
            this.setActionInProgress(false);
            toast.error(ERROR_MESSAGES.DECLINE_CONTACT);
        });
    };

    onContactRequestDecline = (email) => {
        contactsService.declineContactRequest(email).then(() => {
            contactsService.refreshContactList();
        });
    };

    onContactAccept = (email) => {
        const contactReplaceCase = "accept";
        const { existingContacts, replaceContact, contactsLimitModal } = this.props;
        const { subscription } = store.getState().user.info;

        if (contactsService.isContactsLimitExceeded(existingContacts, subscription.type)) {
            if (subscription.type === "free") {
                replaceContact(email, contactReplaceCase);

                return;
            } else {
                contactsLimitModal(true);

                return;
            }
        }
        
        this.setActionInProgress(true);
        
        contactsService.acceptContactRequest(email).then(() => {
            this.setActionInProgress(false);
            contactsService.refreshContactList();
        }).catch((error) => {
            console.error(error);
            this.setActionInProgress(false);
            toast.error(ERROR_MESSAGES.ACCEPT_CONTACT);
        })
    };

    setActionInProgress = (toggle) => {
        this.setState({...this.state, actionInProgress: toggle})
    }
    
    closeConfirmationModal = () => {
        this.setState({
            emailDelete: '',
            isConfirmationModalOpen: false
        });
    };

    onContactDelete = (email) => {
        this.setState({
            isConfirmationModalOpen: true,
            emailDelete: email
        })
    }

    onContactEncrypt = (email) => {
       this.props.action({type: CONTACT_ACTION_TYPES.ENCRYPT, payload: {email: email}})
    };

    render() {
        const {contacts, text, type} = this.props;
        const {expandContacts, isConfirmationModalOpen, emailDelete} = this.state;
        return (
            <>
                {
                    contacts.length > 0 ? (
                        <>
                            <DelimiterCounter text={text} number={contacts.length} onClick={() => {
                                this.expandContacts(!expandContacts)
                            }} isExpanded={expandContacts} type={type}/>
                            {
                                (expandContacts || type === CONTACT_LIST_TYPE.EXISTING) ? <>
                                    {
                                        contacts.map((contact) => {
                                            return (
                                                <Contact key={contact.email}
                                                         contact={contact}
                                                         isLoading={this.state.actionInProgress}
                                                         type={type}
                                                         action={(contactAction) => {
                                                             if (contactAction.type === CONTACT_ACTION_TYPES.DELETE) {
                                                                 this.onContactDelete(contact.email);
                                                             }
                                                             if (contactAction.type === CONTACT_ACTION_TYPES.CLICK) {
                                                                 this.onContactClick(contact.email);
                                                             }
                                                             if (contactAction.type === CONTACT_ACTION_TYPES.DECLINE) {
                                                                 this.onContactDecline(contact.email);
                                                             }
                                                             if (contactAction.type === CONTACT_ACTION_TYPES.REQUEST_DECLINE) {
                                                                 this.onContactRequestDecline(contact.email);
                                                             }
                                                             if (contactAction.type === CONTACT_ACTION_TYPES.ACCEPT) {
                                                                 this.onContactAccept(contact.email);
                                                             }
                                                             if (contactAction.type === CONTACT_ACTION_TYPES.ENCRYPT) {
                                                                 this.onContactEncrypt(contact.email);
                                                             }
                                                         }
                                                         }
                                                />
                                            )
                                        })
                                    }
                                </> : null
                            }
                        </>
                    ) : null
                }
                <ConfirmationModal isOpen={isConfirmationModalOpen}
                                   title={"Delete contact"}
                                   message={"Are you sure you want to delete this contact?"}
                                   confirmButton={'Yes'} declineButton={"No"}
                                   confirmAction={(isConfirmed) => {
                                       if (isConfirmed) {
                                           contactsService.deleteContact(emailDelete).then(() => {
                                               contactsService.refreshContactList();
                                           });
                                       }
                                       this.closeConfirmationModal();
                                   }}/>
            </>
        );
    }
}