import React from 'react';
import kripteraLogo from "../../../assets/web_hi_res_512.c9fc142c.png";
import './NavbarMain.scss';
import NavbarDropdown from "./NavbarDropdown";
import {logout} from "../../../Store/Actions/GeneralActions";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {compose} from "redux";
import TopMainTabs from "../TopMainTabs/TopMainTabs";
import { SettingsModal } from "../../Modals/SettingsModal/SettingsModal";
import {setToken} from "../../../global";

class NavbarMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            settingsIsOpen: false
        }
        console.log('navigator.userAgent',navigator.userAgent);
    }

    setSettingsIsOpen = (toggle) => {
        this.setState({
            settingsIsOpen: toggle
        });
    }
    
    render() {
        return (
            <nav className="navbar navbar-light bg-light d-flex justify-content-between pt-0 pb-0">
                <a className="navbar-brand ps-2" href="#">
                    <img src={kripteraLogo} width="45" height="45" alt="" />
                </a>
                <TopMainTabs/>
                <NavbarDropdown
                    onSettings={ () => this.setSettingsIsOpen(true)}
                    onLogout={() => {
                        this.props.logout();
                        this.props.history.push('/login');
                    }}/>
                <SettingsModal isOpen={this.state.settingsIsOpen} toggle={this.setSettingsIsOpen}/>
            </nav>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout()),
    };
}

export default compose(connect(null, mapDispatchToProps), withRouter)(NavbarMain);