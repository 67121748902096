import HttpRefreshToken from "../Http/HttpRefreshToken";

export default class FileHistoryService {
    http = new HttpRefreshToken();

    getFiles = async () => {
        const files = this.http.getFileHistory();
        return files;
    };

    pushFile = (email, file, action) => {
        const name = file.name;
        const size = file.size;
        this.http.pushToFileHistory(email, name, action, size, '123');
    };
}