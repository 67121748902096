import ValidationModel from "../Model/ValidationModel";

export default class ValidateUserInput {
    validationModel = new ValidationModel();

    validate = (registrationModel) => {

        this.validationModel.isFirstNameEmpty = !(registrationModel.firstname && registrationModel.firstname.length > 0);
        this.validationModel.isLastNameEmpty = !(registrationModel.lastname && registrationModel.lastname.length > 0);
        this.validationModel.isEmailEmpty = !(registrationModel.email && registrationModel.email.length > 0);
        this.validationModel.isEmailConfirmEmpty = !(registrationModel.confirmEmail && registrationModel.confirmEmail.length > 0);
        this.validationModel.isPasswordEmpty = !(registrationModel.password && registrationModel.password.length > 0);
        this.validationModel.isPasswordConfirmEmpty = !(registrationModel.confirmPassword && registrationModel.confirmPassword.length > 0);

        this.validationModel.isEmailInvalid = !this.isEmailValid(registrationModel.email);
        this.validationModel.isPasswordNotComplex = !this.isPasswordComplex(registrationModel.password);

        this.validationModel.isEmailMismatch = this.checkForMismatch(registrationModel.email, registrationModel.confirmEmail);
        this.validationModel.isPasswordMismatch = this.checkForMismatch(registrationModel.password, registrationModel.confirmPassword);

        this.validationModel.validate();

        return this.validationModel;
    };

    checkForMismatch = (text1, text2) => {
        return text1 !== text2;
    };

    isEmailValid(email) {
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    isPasswordComplex = (password) => {
        const oneDigitRegEx = new RegExp('[0-9]', 'g');
        const oneCapitalLetter = new RegExp('[A-Z]', 'g');

        if (!oneCapitalLetter.test(password)) {
            return false;
        } else if (!oneDigitRegEx.test(password)) {
            return false;
        } else if (password.length < 8) {
            return false;
        } else if (password.length > 32) {
            return false;
        } else {
            return true;
        }

    };

    registrationFormIsFilled = (registrationModel) => {
        let isFilled = true;

        if (registrationModel.firstname && registrationModel.lastname
            && registrationModel.email && registrationModel.confirmEmail
            && registrationModel.password && registrationModel.confirmPassword) {
                return isFilled;
        }

        isFilled = false;

        return isFilled;
    };
}